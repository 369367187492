import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { HiOutlineShoppingCart, HiPhone, HiMailOpen, HiLocationMarker, HiSearch } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { seoData } from '../../../data';

const ContactUs = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [banner, setBanner] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [pending, setPending] = useState(false)
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    // const [heroData, setHeroData] = useState([]);
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');

    const getData = () => {
        axios.get(`${api_url}/adminProfile`).then((response) => {

            // setBanner(response?.data?.data[0]?.image)
            setData(response?.data?.data)
            setPending(true)
        }).catch((err) => {
            console.log(err)
        });


        axios.get(`${api_url}/getseo/6`).then((response) => {
            setSeoContent(response?.data?.data);

        }).catch((err) => {
            console.log(err);
        })

        axios.get(`${api_url}/11`).then((response) => {

            setBanner(response?.data?.data[0]?.image)
            // setHeroData(response?.data?.data)
        }).catch((err) => {
            console.log(err)
        });

        // axios.get(`${api_url}/adminProfile`).then((response) => {

        //     setLogo(response?.data?.data[0]?.logo)
        //     setData(response?.data?.data)
        // }).catch((err) => {
        //     console.log(err);
        // })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, []);

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);


    const enqSuccess = () => toast.success('Enquiry send successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        axios.post(`${api_url}/Enquiry`, {
            name: name,
            email: email,
            phone: phone,
            subject: subject,
            message: message
        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                enqSuccess();
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('')
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const blockedWords = [
        'Offer',
        'Credit',
        'Loans',
        'Winner',
        'Investment',
        'Pharmacy',
        'Viagra',
        'Cialis',
        'Weight loss',
        'SEO',
        'Web traffic',
        'Click here',
        'Subscribe',
        'Buy now',
        'Order now',
        'Promotion',
        'Marketing',
        'Affiliate',
        'Billion',
        'Million',
        'Cash bonus',
        'proven techniques',
        'google'
    ];

    const validateLetters = (value) => {
        // Remove spaces from the value and count the letters
        const letterCount = value.replace(/\s/g, '').length;
        return letterCount >= 25; // Minimum of 25 letters required
    };

    const validateBlockedWords = (value) => {
        // Check if value contains any blocked words
        const containsBlockedWords = blockedWords.some(word =>
            value.toLowerCase().includes(word.toLowerCase())
        );

        // Return false if contains blocked words, else true
        return !containsBlockedWords;
    };


    return (
        <>


            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.contactUs.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.contactUs.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.contactUs.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.contactUs.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.contactUs.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.contactUs.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>



            {/* <div className="page-banner-area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-image">
                                <img src="https://thepathwayhypnotherapyrooms.com/wp-content/uploads/2022/09/image_2022_09_16T11_45_08_426Z.png" alt="image" style={{ display: "block", visibility: "visible" }} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-content">
                                <h2>Contact</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-banner-shape">
                    <img data-cfsrc="assets/images/page-banner/banner-shape-1.png" alt="image" style={{ display: "none", visibility: "hidden" }} />
                </div>
            </div> */}

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}


            <ToastContainer />

            <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                <div className="container">
                    <div className="page-banner-content-two">
                        <h2>Contact Us</h2>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>


            <section className="contact-info-area pt-100 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        {data?.map((data) => {
                            return (
                                <>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="contact-info-box">
                                            <div className="icon">
                                                <a href={`tel: ${data.phone}`}>
                                                    <i><HiPhone /></i>
                                                    <h3>Phone Number</h3>
                                                </a>
                                            </div>

                                            <p><i className="flaticon-check"></i> <a href={`tel: ${data.phone}`}>{data.phone}</a></p>
                                            {/* <p><i className="flaticon-check"></i> <a href="">1234567789</a></p> */}
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="contact-info-box">
                                            <div className="icon">
                                                <a href={`mailto:glen@thepathwayhypnotherapyrooms.co.uk`}>
                                                    <i><HiMailOpen /></i>
                                                    <h3>Email Address</h3>
                                                </a>
                                            </div>

                                            <p><i className="flaticon-check"></i><a href={`mailto:glen@thepathwayhypnotherapyrooms.co.uk`}><span className="__cf_email__" data-cfemail="">glen@thepathwayhypnotherapyrooms.co.uk</span></a></p>
                                            {/* <p><i className="flaticon-check"></i><a href=""><span className="__cf_email__" data-cfemail="">[abc@gmail.com]</span></a></p> */}
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="contact-info-box">
                                            <div className="icon">
                                                <a target='_blank' href="https://goo.gl/maps/PKUSRk8zNxwDHfwn7">
                                                    <i><HiLocationMarker /></i>
                                                    <h3>Address</h3>
                                                </a>
                                            </div>

                                            <p><i className="flaticon-check"></i><a target='_blank' href="https://goo.gl/maps/PKUSRk8zNxwDHfwn7">{data.address}</a></p>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="contact-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-form">
                                <h1>Hypnotherapy Rooms Hitchin <br></br><p>Better yet, gives us a call as it would be great speaking to you!</p></h1>
                                <h3>Drop us a <span>Line!</span></h3>

                                <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <input type="text" id="name" className="form-control" placeholder="Name"
                                            value={name}

                                            {...register('name', {
                                                required: true,
                                                onChange: (e) => setName(e.target.value)

                                            })}
                                        />
                                        {errors.name && errors.name.type && <div className="help-block with-errors text-danger">please enter your name</div>}

                                    </div>

                                    <div className="form-group">
                                        <input type="email" id="email" className="form-control" placeholder="Email"
                                            value={email}

                                            {...register('email', {
                                                required: true,
                                                onChange: (e) => setEmail(e.target.value)
                                            })}
                                        />
                                        {errors.email && errors.email.type && <div className="help-block with-errors text-danger">please enter email</div>}
                                    </div>

                                    <div className="form-group">
                                        <input type="text" id="phone_number" className="form-control" placeholder="Phone"
                                            value={phone}

                                            {...register('phone', {
                                                required: true,
                                                onChange: (e) => setPhone(e.target.value)
                                            })}
                                        />
                                        {errors.phone && errors.phone.type && <div className="help-block with-errors text-danger">please enter phone</div>}
                                    </div>

                                    <div className="form-group">
                                        <input type="text" id="msg_subject" className="form-control" placeholder="Subject"
                                            value={subject}

                                            {...register('subject', {
                                                required: true,
                                                onChange: (e) => setSubject(e.target.value)
                                            })}
                                        />
                                        {errors.subject && errors.subject.type && <div className="help-block with-errors text-danger">please enter subject</div>}
                                    </div>

                                    <div className="form-group">
                                        <textarea className="form-control" id="message" cols="30" rows="5" placeholder="Your Message"
                                            value={message}

                                            {...register('message', {
                                                required: 'please type message',
                                                validate: {
                                                    hasEnoughLetters: value => validateLetters(value) || 'Message must contain at least 25 letters (excluding spaces)',
                                                    noBlockedWords: value => validateBlockedWords(value) || `Message contains blocked words: ${blockedWords.filter(word => value.toLowerCase().includes(word.toLowerCase())).join(', ')}`,
                                                },
                                                onChange: (e) => setMessage(e.target.value)
                                            })}
                                        ></textarea>
                                        {errors.message && errors.message.type && <div className="help-block with-errors text-danger">{errors.message.message}</div>}
                                    </div>

                                    {/* <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="accept" />
                                        <label className="form-check-label" htmlFor="accept">I Accept All <a href="terms-of-service.html">Terms & Condition</a></label>
                                    </div> */}

                                    <button type="" className="default-btn">Send Message <i><BsFillHandIndexThumbFill /></i></button>
                                    {/* <div id="msgSubmit" className="h3 text-center hidden"></div>
                                    <div className="clearfix"></div> */}
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="contact-image">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9821.968423254442!2d-0.2306729!3d52.0161322!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487633198868ee63%3A0x375d95449a9ff1d4!2sPathway%20Hypnotherapy%20Rooms!5e0!3m2!1sen!2sin!4v1684918317277!5m2!1sen!2sin" width="100%" height="100%" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                {/* <iframe src={data[0]?.map} width="100%" height="100%" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default ContactUs