import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import HTMLRenderer from 'react-html-renderer'
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { Button } from 'react-bootstrap';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import { seoData } from '../../../data';

const ThePathwayStill = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [pathwayStill, setPathwayStill] = useState([]);
  const [heroData, setHeroData] = useState([]);
  const [banner, setBanner] = useState('');
  const [pending, setPending] = useState(false)
  const [seoContent, setSeoContent] = useState([]);
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');
  const navigate = useNavigate();
  const [featureImage, setFeatureImage] = useState('');



  const getData = () => {
    axios.get(`${api_url}/getBanner/6`).then((response) => {
      setBanner(response?.data?.data[0]?.image)
      setHeroData(response?.data?.data)
    }).catch((err) => {
      console.log(err)
    });


    axios.get(`${api_url}/getseo/5`).then((response) => {
      setSeoContent(response?.data?.data);
    }).catch((err) => {
      console.log(err);
    })

    axios.get(`${api_url}/getPathwayStill`).then((response) => {
      setPathwayStill(response?.data?.data);
      setPending(true)
    }).catch((err) => {
      console.log(err)
    });

  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
    if (window.location.hash.includes('##')) {
      navigate('/');
    }
  }, [])

  const handleSEO = () => {
    seoContent?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
      setFeatureImage(seo.feature_image);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [seoContent]);



  return (
    <>

      <Helmet>
        <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
        <title>{seoTitle ? seoTitle : seoData.pathwayStill.title}</title>
        <meta name="description" content={seoDes ? seoDes : seoData.pathwayStill.description} />
        <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
        <meta property="og:title" content={seoTitle ? seoTitle : seoData.pathwayStill.title} />
        <meta property="og:description" content={seoDes ? seoDes : seoData.pathwayStill.description} />
        <meta property="og:image" content={featureImage} />
        <meta name="robots" content={robot} />
        <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
        <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.pathwayStill.title} />
        <meta name="twitter:description" content={seoDes ? seoDes : seoData.pathwayStill.description} />
        <meta name="twitter:image" content={featureImage}></meta>
        <script>{seoScript}</script>
      </Helmet>



      {pending ? null :
        <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
          <Spinner animation="border" variant="dark" className='my_spinner' />
        </div>}



      <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
        <div className="container">
          <div className="page-banner-content-two">
            <h2>The Pathway Still</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>The Pathway Still</li>
            </ul>
          </div>
        </div>
      </div>


      <section className="still_ offer-area"
      // style={{ backgroundImage: `url(${bannerNew})` }}
      // -fluid
      >
        <div className="container">
          <div className="row align-items-center">
            {pathwayStill?.map((data) => {

              return (
                <>
                  <div className="col-lg-6">
                    <div className="offer-item">
                      <div className="content">
                      </div>
                      <h1 className='mb-3'>Still Method For Anxiety Uk</h1>
                      <p>
                        <HTMLRenderer html={data.content} />
                      </p>
                      <Button className="contact_btn default-btn"><Link to="/contact-us"><h2>Children's Anxiety Coach Scotland</h2><i><BsFillHandIndexThumbFill /></i></Link></Button>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="offer-image text-center">
                      <img className='p-5' src={`${api_url}/assets/pathwaystill/${data.image}`} alt="image" />
                    </div>
                  </div>
                </>
              )
            })}

          </div>
        </div>
      </section>
    </>
  )
}

export default ThePathwayStill