import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FaHandPointUp, FaUser, FaPlus } from 'react-icons/fa';
import { MdOutlineMiscellaneousServices } from 'react-icons/md';
import { AiOutlineShoppingCart, AiTwotoneCustomerService } from 'react-icons/ai';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import bannerNew from '../assets/banner.png'
import axios from 'axios';
import Rating from '@mui/material/Rating';
import { Pagination } from "swiper/modules";
import aboutImg from '../assets/about.png';
import { BsFillHandIndexThumbFill, BsArrowRightShort } from 'react-icons/bs';
import { useOutletContext, useParams } from 'react-router-dom';



const RelatedProduct = ({ data, title }) => {

    const api_url = process.env.REACT_APP_API_URL;

    const [addToCart] = useOutletContext();


    return (
        <>
            {/*  pt-100 */}
            <section className="products-area">
                <div className="container-fluid">
                    <div className="section-title">
                        <h2>{title}</h2>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>

                    <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        loop={false}
                        autoplay={false}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper text-center"
                        breakpoints={{
                            30: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 50,
                            },
                          }}
                    >
                        {data?.map((data) => {
                            return (
                                <SwiperSlide>
                                    <div className="products-item">
                                        <div className="products-image">
                                            <Link to={`/shop/${data.id}/${data.name.replace(/\s+/g, '-')}`}><img src={`${api_url}/assets/product/${data.image}`} alt="image" /></Link>
                                            {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == data.id) === false ?
                                                <div className="action-btn">
                                                    <button onClick={() => addToCart(data.id, data.name, 1, data.price, data.image)} className="default-btn">Add To Cart<i><AiOutlineShoppingCart /></i></button>
                                                </div>
                                                :
                                                <div className="action-btn">
                                                    <button className="default-btn"
                                                        disabled
                                                    >Added to Cart<i><AiOutlineShoppingCart /></i></button>
                                                </div>
                                              
                                            }
                                        </div>

                                        <div className="products-content">
                                            <h3>
                                                <Link to={`/shop/${data.id}/${data.name.replace(/\s+/g, '-')}`}>{data.name}</Link>
                                            </h3>
                                            <span>&#163;{data.price}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper>

                    {/* <div className="book-store-btn">
                        <span className="default-btn" style={{ cursor: "pointer" }}
                   
                        >Visit Book Store <i><AiOutlineShoppingCart /></i></span>
                    </div> */}
                </div>
                {/* <div className="book-store-btn">
                    <Link to="/shop" className="default-btn">View More<i><BsArrowRightShort fontSize={20} /></i></Link>
                </div> */}

            </section>
        </>
    )
}

export default RelatedProduct