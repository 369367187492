import React, { useState, useEffect, useMemo } from 'react';
import { FiCheck } from 'react-icons/fi'

import { useForm, Controller } from 'react-hook-form';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import CountrySelect from 'react-bootstrap-country-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Paypal from '../common/payment/Paypal';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";


const Checkout = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [addToCart] = useOutletContext();

    const [cartData, setCartData] = useState([]);
    const [subTotal, setSubTotal] = useState(0)
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate();
    const [transactionId, setTransactionId] = useState('');
    const [isPay, setIsPay] = useState(false)
    const [pending, setPending] = useState(false)
    const [client_Id, setClient_Id] = useState('');
    // const [subject, setSubject] = useState('')
    // const [message, setMessage] = useState('')

    // console.log(cartData.map(data => data.id).toString());

    const [value, setValue] = useState(null);
    const [countryErr, setCountryErr] = useState('');


    const saveCart = (newData) => {

        localStorage.setItem("myCart", JSON.stringify(newData));

        let subt = 0;
        newData?.forEach(element => {
            subt += element.price * element.qty
        });
        setSubTotal(subt)
    }


    const getData = () =>{
        axios.get(`${api_url}/getAdminSetting1`).then((response) => {
            
            setClient_Id(response?.data?.data[0]?.paypal_client)
          }).catch((err) => {
           
            console.log(err);
          })
    }

    useEffect(() => {
        try {
            if (localStorage.getItem("myCart")) {
                let myCart = JSON.parse(localStorage.getItem("myCart"))
                setCartData(myCart)
                saveCart(myCart)
                setPending(true)
            }
        } catch (error) {
            console.error(error);
            localStorage.setItem("myCart", JSON.stringify([]))
        }

    }, [localStorage.getItem("myCart")])


    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [])

    // useEffect(() => {
    //     console.log(localStorage.getItem("myCart"))
    // }, [JSON.parse(localStorage.getItem("myCart")])

    // console.log(JSON.parse(localStorage.getItem("myCart")))

    const handleCounty = (e) => {
        setValue(e)
        setCountry(e.name)
    }




    const checkoutSuccess = () => toast.success('Successfully Checkout', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();


    const verified = () => {
        if (country != '') {
            setCountryErr('')
            if (subTotal > 0) {
                setIsPay(true);
            } else {
                setIsPay(false);
                onSubmit('');
            }

        } else {
            setCountryErr('please select country')
            setIsPay(false);
        }

    }

    const onSubmit = (t_id) => {
        setPending(false)
        axios.post(`${api_url}/CheckoutData`, {
            first_name: name,
            last_name: lastName,
            email: email,
            phone: phone,
            address: address,
            city: city,
            state: state,
            zipcode: zipCode,
            total_price: subTotal,
            country: country,
            transaction_id: t_id,
            product_id: cartData?.map(data => data.id).toString(),

            // subject: subject,
            // message: message
        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {

                localStorage.setItem("myCart", JSON.stringify([]))
                navigate(`/success/${response.data.bookingId}`)
                checkoutSuccess();
                setPending(true);
                // window.location.reload(false);
                setName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setCountry('')
                setState('');
                setCity('');
                reset({
                    name: '',
                    lname: '',
                    email: '',
                    phone: '',
                    address: '',
                    state: '',
                })

            }
        }).catch((err) => {
            console.log(err);
        })
    }


    // let paypalAuth = "AeHEpAHObLC3n97VXKs3iGgJOO5wXm6vvd75EzfJ8U10wq_KfFBICH-k-jNq3T429Ma988wOzCEBtIFz";




    return (
        <>



    <Helmet>
        <title>Checkout | The Pathway Hypnotherapy Rooms</title>
        <meta name="description" content="Check out The Pathway Hypnotherapy Rooms." />
        <meta property="og:title" content="Checkout | The Pathway Hypnotherapy Rooms" />
        <meta property="og:description" content="Check out The Pathway Hypnotherapy Rooms.
" />
      </Helmet>

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <ToastContainer />

            <div className="page-banner-with-full-image item-bg4" >
                <div className="container">
                    <div className="page-banner-content-two">
                        <h2>Checkout</h2>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/shop">Products</Link>
                            </li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="checkout-area ptb-100">
                <div className="container">
                    {/* <div className="user-actions">
                        <i className='bx bx-log-in'></i>
                        <span>Returning customer? <a href="#">Click here to login</a></span>
                    </div> */}

                    <form onSubmit={handleSubmit(verified)}>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="billing-details">
                                    <h3 className="title">Billing Details</h3>

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>First Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" placeholder='first name'
                                                    value={name}

                                                    {...register('name', {
                                                        required: true,
                                                        onChange: (e) => setName(e.target.value)

                                                    })}
                                                />
                                                {errors.name && errors.name.type && <div className="help-block with-errors">please enter first name</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Last Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" placeholder='last name'
                                                    value={lastName}
                                                    {...register('lname', {
                                                        required: true,
                                                        onChange: (e) => setLastName(e.target.value)

                                                    })}
                                                />
                                                {errors.lname && errors.lname.type && <div className="help-block with-errors">please enter last name</div>}
                                            </div>
                                        </div>

                                        {/* <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Company Name</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div> */}

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Email Address <span className="required">*</span></label>
                                                <input type="email" className="form-control" placeholder='email'
                                                    value={email}
                                                    {...register('email', {
                                                        required: true,
                                                        onChange: (e) => setEmail(e.target.value)
                                                    })}
                                                />
                                                {errors.email && errors.email.type && <div className="help-block with-errors">please enter email</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Phone <span className="required">*</span></label>
                                                <input type="text" className="form-control" placeholder='phone'
                                                    value={phone}
                                                    {...register('phone', {
                                                        required: true,
                                                        onChange: (e) => setPhone(e.target.value)
                                                    })}
                                                />
                                                {errors.phone && errors.phone.type && <div className="help-block with-errors">please enter phone</div>}
                                            </div>
                                        </div>




                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Country <span className="required">*</span></label>


                                                <CountrySelect
                                                    value={value}
                                                    onChange={handleCounty}
                                                    placeholder='country'

                                                />

                                                {<div className="help-block with-errors">{countryErr}</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>State / County
                                                    {/* <span className="required">*</span> */}
                                                </label>
                                                <input type="text" className="form-control" placeholder='state'
                                                    value={state}
                                                    {...register('state', {
                                                        required: true,
                                                        onChange: (e) => setState(e.target.value)

                                                    })}
                                                />
                                                {errors.state && errors.state.type && <div className="help-block with-errors">please enter state/county</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Town / City <span className="required">*</span></label>
                                                <input type="text" className="form-control" placeholder='city'
                                                    value={city}
                                                    {...register('city', {
                                                        required: true,
                                                        onChange: (e) => setCity(e.target.value)

                                                    })}
                                                />
                                                {errors.city && errors.city.type && <div className="help-block with-errors">please enter Town/City</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Postcode / Zip <span className="required">*</span></label>
                                                <input type="text" className="form-control" placeholder='zipcode'
                                                    value={zipCode}
                                                    {...register('zipCode', {
                                                        required: true,
                                                        onChange: (e) => setZipCode(e.target.value)

                                                    })}
                                                />
                                                {errors.zipCode && errors.zipCode.type && <div className="help-block with-errors">please enter zipcode</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label>Address <span className="required">*</span></label>
                                                <input type="text" className="form-control" placeholder='address'
                                                    value={address}
                                                    {...register('address', {
                                                        required: true,
                                                        onChange: (e) => setAddress(e.target.value)

                                                    })}
                                                />
                                                {errors.address && errors.address.type && <div className="help-block with-errors">please enter address</div>}
                                            </div>
                                        </div>


                                        {/* <div className="col-lg-12 col-md-12">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="create-an-account" />
                                                <label className="form-check-label" htmlFor="create-an-account">Create an account?</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="ship-different-address" />
                                                <label className="form-check-label" htmlFor="ship-different-address">Ship to a different address?</label>
                                            </div>
                                        </div> */}

                                        {/* <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Order Notes" className="form-control"></textarea>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="order-details">
                                    <h3 className="title">Your Order</h3>

                                    <div className="order-table table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {cartData?.map((data) => {
                                                    return (
                                                        <tr>
                                                            <td className="product-name">
                                                                <span>{data.name}</span>
                                                            </td>

                                                            <td className="product-total">
                                                                <span className="subtotal-amount">£ {data.price}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                <hr style={{ width: '120%' }}></hr>

                                                <tr>
                                                    <td className="product-name">
                                                        <span className='order_'>Order Total</span>
                                                    </td>

                                                    <td className="product-total">
                                                        <span className="order_ subtotal-amount"> £ {subTotal}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="payment-box">
                                        <div className="payment-method">

                                        </div>
                                        {cartData?.length > 0 && isPay === false ?
                                            <Button className="default-btn" type="submit">
                                                Place Order <i><FiCheck style={{ fontSize: '24px' }} /></i>
                                            </Button> : null}

                                        {cartData?.length > 0 && isPay === true && subTotal > 0 ?
                                            <Paypal client_Id={client_Id} amount={subTotal} onBooking={onSubmit} setTransactionId={setTransactionId} />
                                            : null}
                                        {/* <a href="#" className="default-btn">Place Order <i><FiCheck style={{ fontSize: '24px' }} /></i></a> */}

                                        {/* <Paypal amount={subTotal} onBooking={onSubmit} setTransactionId={setTransactionId} /> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Checkout