import axios from 'axios';
import React, { useState, useEffect, memo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { Button } from 'react-bootstrap';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import { seoData } from '../../../data';

const SingleTherapies = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();

    const [therapies, setTherapies] = useState([]);
    const [pending, setPending] = useState(false)
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');

    const getData = () => {


        axios.get(`${api_url}/singleTherapies/${id}`).then((response) => {

            setTherapies(response?.data?.data);
            setSeoContent(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });


    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();

    }, [id])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    let defaultSeoData = seoData.blogDetails.filter(item => item.id == id);

    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : defaultSeoData[0]?.title}</title>
                <meta name="description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta property="og:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="blog-details-area " style={{ padding: "45px 0px" }}>
                <div className="container">

                    <div className="page-banner-content">

                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/therapies">Therapies</Link>
                            </li>
                            <li>
                                {therapies[0]?.title}
                            </li>

                        </ul>
                    </div>
                    <div className="blog-details-desc pt-4">
                        {therapies?.map((therapy) => {
                            return (
                                <div className="article-content therapies-d">

                                    <div className="title-box">
                                        <h1>{therapy.title}</h1>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-6 col-12'>
                                            <div className="article-image pb-4">
                                                <img className='w-100' src={`${api_url}/assets/therapies/${therapy.image}`} alt="image" />
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 col-12'>

                                            <HTMLRenderer html={therapy.content} />

                                            <Button className="contact_btn default-btn"><Link to="/contact-us"><h2>Contact Us</h2><i><BsFillHandIndexThumbFill /></i></Link></Button>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(SingleTherapies)