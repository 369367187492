import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import { useLocation, Navigate } from 'react-router-dom';

const Error = () => {

    const location = useLocation();
    const [isGoogleCache, setIsGoogleCache] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        const referrer = document.referrer;
        // Detect if the request is coming from Google Cache
        if (referrer.includes('googleusercontent.com')) {
            setIsGoogleCache(true);
        }
    }, [location]);

    if (isGoogleCache) {
        // Redirect to the home page or current path if it's a Google Cache request
        return <Navigate to="/" replace />;
    }

    return (
        <>
            <section class="error-area ptb-100">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content">
                                {/* <img src="images/images-404.png" alt="error"> */}

                                <h3>Error 404 : page not found</h3>


                                <Link to="/" class="default-btn">Go To Home <i><BsFillHandIndexThumbFill /></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Error