export const seoData = {
    home: {
        title: "Hypnotherapy Services in Cambridge, Northampton, Hertfordshire & More",
        description: "Explore top-notch hypnotherapy services in Milton Keynes, Newmarket, Aylesbury, Hitchin, St Albans and more. Tailored sessions to meet your wellness goals.",
        canonical: "https://thepathwayhypnotherapyrooms.co.uk/"
    },
    about: {
        title: "About Us - The Pathway Hypnotherapy Rooms|Glen Russell",
        description: "Glen Russell Hypnotherapist In England and Herts . He is a qualified remedial Hypnotist, a STILL Method Coach, and an Approved Anxiety UK Therapist. Contact Us 07497157531."
    },
    controlSystem: {
        title: "The Control System | The Pathway Hypnotherapy Rooms UK",
        description: "The Control System Hypnotherapy is a direct hands-on method of creating rapid and positive change in the way you think, feel and behave. Contact Us at 07497157531."
    },
    pathwayStill: {
        title: "Still Method For Children's Anxiety in Scotland, UK By Glen Russell",
        description: "The Still Method was created as a tool to help children and adults manage and overcome anxiety. Contact Us at 07497157531 to Children's Anxiety Coach Scotland, UK."
    },
    therapy: {
        title: "Our Therapies in Hitchin, UK | The Pathway Hypnotherapy Rooms",
        description: "All the therapy services offered by Glen Russell at The Pathway Hypnotherapy Rooms. Contact 07497157531 us to fix any problem in your life with hypnotherapy in Hitchin."
    },
    blog: {
        title: "Blogs | The Pathway Hypnotherapy Rooms",
        description: "Check out The Pathway Hypnotherapy Rooms."
    },
    contactUs: {
        title: "Contact US 07497157531 |Glen Russell | Qualified remedial Hypnotist",
        description: "Glen Russell is a qualified remedial Hypnotist, a STILL Method Coach, & Best Hypnotherapist In England, UK. Contact 07497157531or email us at glen@thepathwayhypnotherapyrooms.co.uk."
    },
    shop: {
        title: "Hypnosis Recordings | The Pathway Hypnotherapy Rooms UK",
        description: "These recordings provide individuals with the opportunity to experience the benefits of hypnosis in the comfort of their own space and at their convenience. Book now"
    },
    reviews: {
        title: "Testimonials & Review | The Pathway Hypnotherapy Rooms",
        description: "Check out The Pathway Hypnotherapy Rooms Testimonials for your reference and book your services with us."
    },
    therapies: [
        {
            id: 3,
            title: "Hypno-Gastric Band Therapy England, UK|Glen Russell",
            description: "Gastric Band Hypnotherapy is about understanding your relationship with food & removing the emotional & physical triggers that cause overeating. Call Us 07497157531."
        },
        {
            id: 5,
            title: "Best Hypnobirthing & Pregnancy Hypnotherapy in Hitchin, UK|Glen Russell",
            description: "Enquire or make an appointment with certified Hypnobirthing Practitioners Glen Russell offering the best Hypnobirthing & Pregnancy in Hitchin, UK. Call Now 07497157531."
        },
        {
            id: 14,
            title: "Hypnotherapy For Overcoming Addiction England | Glen Russell",
            description: "Hypnotherapy is an effective approach for overcoming addiction that utilizes the power of the subconscious mind to facilitate positive behavioral changes. Call Now"
        },
        {
            id: 13,
            title: "Hypnotherapy For Overcoming Gambling England | Glen Russell",
            description: "Hypnotherapy is an effective approach for overcoming Gambling that utilizes the power of the subconscious mind to facilitate positive behavioral changes. Call Now"
        },
        {
            id: 15,
            title: "Hypnotherapy For Stopping Smoking in England | Glen Russell",
            description: "Hypnotherapy for Stopping or quit Smoking can be an effective method in challenging unhelpful mindsets and negative behaviours. Contact Us to Stop Smoking Hypnosis in England."
        },
        {
            id: 10,
            title: "Hypnotherapy for Menopause in Hitchin, UK| Glen Russell",
            description: "Hypnosis for the Menopause aims to help you manage specific symptoms. Get an appointment with a top counsellor and hypnotherapy expert today. Call Now 07497157531."
        },
        {
            id: 12,
            title: "Hypnotherapy For Weight Loss Treatment England| Glen Russell",
            description: "Hypnotherapy for Weight Loss Treatment in England by Glen Russell helps you access your own subconscious mind & find out the source & emotional cause of the weight gain. Call Now!"
        }
    ],
    blogDetails: [
        {
            id: 14,
            title: "Exploring Hypnotherapy for Stress Relief: A Journey to Wellness",
            description: "In the ongoing journey towards achieving wellness and personal growth, various approaches are explored to address challenges like weight management."
        },
        {
            id: 13,
            title: "Breaking Free: A Post-Xmas Reflection on Vaping and the Power of Hypnotherapy",
            description: "The holiday season has come and gone, leaving behind the warmth of cherished moments and the echoes of joyous celebrations."
        },
        {
            id: 11,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "Check out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 10,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "Check out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 9,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "Check out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 8,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "Check out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 7,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "heck out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 6,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "heck out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 3,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "heck out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 2,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "heck out The Pathway Hypnotherapy Rooms."
        },
        {
            id: 1,
            title: "Blogs | The Pathway Hypnotherapy Rooms",
            description: "heck out The Pathway Hypnotherapy Rooms."
        }
    ],
    products: [
        {
            id: 4,
            title: "Relaxation Recording -1 | The Pathway Hypnotherapy Rooms UK",
            description: "The relaxation recording is a soothing and therapeutic audio session designed to induce a state of deep relaxation and promote overall well-being. Book now"
        },
        {
            id: 3,
            title: "Anxiety Reduction - Recording | The Pathway Hypnotherapy Rooms UK",
            description: "The anxiety reduction recording is a therapeutic audio session specifically designed to alleviate anxiety and promote a sense of calm and relaxation. Book now"
        },
        {
            id: 2,
            title: "Anxiety Reduction - Recording | The Pathway Hypnotherapy Rooms UK",
            description: "The anxiety reduction recording is a therapeutic audio session specifically designed to alleviate anxiety and promote a sense of calm and relaxation. Book now"
        },
        {
            id: 1,
            title: "Hypnosis Taster Recording | The Pathway Hypnotherapy Rooms UK",
            description: "This session provides a taste of what hypnosis feels like and how it can be utilized for personal growth and positive change. Book now"
        }
    ]
}