import React, { useState, useEffect, useContext } from 'react'
import './forms.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from 'react-bootstrap';
import logo from '../assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Paypal from '../common/payment/Paypal';
import { LogoContext } from '../../Forms';

const WeightReleaseProgramme = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const logo = useContext(LogoContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pending, setPending] = useState(true);
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isPay, setIsPay] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [amount, setAmount] = useState('')
  const [client_Id, setClient_Id] = useState('');


  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('1 Module Programme', 1, 'N/A', '£1625', '7-8 months'),
    createData('3 Module Programme', 1, 2, '£2725', '7-8 months'),
    createData('4 Module Programme', 1, 3, '£3275', '7-8 months'),
    createData('8 Module Programme', 1, 7, '£5475', '7-8 months'),
  ];


  const getData = () => {
    axios.get(`${api_url}/getamountsetting`).then((response) => {

      setAmount(response?.data?.data[0]?.minimun_amt)
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

    axios.get(`${api_url}/getAdminSetting1`).then((response) => {

      setClient_Id(response?.data?.data[0]?.paypal_client)
    }).catch((err) => {

      console.log(err);
    })
  }

  useEffect(() => {
    getData();
  }, []);


  const programModules = [
    'Weight Release', 'Acceptance', 'Addiction', 'Alcoholism',
    'Allergies', 'Anger issues', 'Anxiety', 'Bad Habits', 'Bed Wetting', 'Bereavement', 'Blushing', 'Body Confidence',
    'Body Dysmorphia', 'Bruxism', 'Confidence', 'Claustrophobia', 'Creating New Habits', 'Depression', 'Drugs'
    , 'Eating Disorder', 'Emetophobia', 'Exercise', 'Fears', 'Feeling safe in the world', 'Fibromyalgia', 'Focus', 'Forgiveness',
    'Grief', 'Guilt', 'Headaches', 'Health Anxiety',
    'Living an abundant life', 'IBS', 'Insomnia / Sleep', 'Intrusive thoughts', 'Jealously', 'Limiting Beliefs', 'Memory / Learning', 'Menopause'
    , 'Migraines', 'Misophonia', 'Motivation', 'Nail Biting', 'Nightmares/Night Terrors', 'Panic Attacks', 'Pain Relief', 'Performance',
    'Phobias', 'Positivity', 'Procrastination', 'Public Speaking',
    'Regret', 'Relationships', 'Restless Leg Syndrome', 'Self-awareness', 'Self Esteem', 'Sexuality', 'Sexual Disfunction', 'Shaking Hands'
    , 'Shame', 'Shopping Confidence', 'Sleep apnoea', 'Snoring', 'Social Anxiety', 'Sports Performance', 'Stammering', 'Stress Relief/Management',
    'Ticks', 'Tinnitus', 'Trauma', 'Trichotillomania'
  ];


  const handleSelectedModule = (e) => {

    let checkedData = [...selectedModule];

    if (e.checked) {
      checkedData = [...selectedModule, { value: e.value }]

    } else {
      let Index = selectedModule.findIndex(data => data.value == e.value)
      checkedData.splice(Index, 1)
    }

    let myData = checkedData
    setSelectedModule(myData);



  }

  const handleSelectedData = (e) => {

    let checkedData = [...selectedData];

    if (e.checked) {
      checkedData = [...selectedData, { id: e.id, value: e.value }]

    } else {
      let Index = selectedData.findIndex(data => data.value == e.value && data.id == e.id)
      checkedData.splice(Index, 1)
    }

    let myData = checkedData

    setSelectedData(myData);


  }


  const verified = () => {
    setIsPay(true);
  }


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const notify = () => toast.success("Form Submit SuccessFully", { position: "top-center" });
  const onSubmit = () => {
    setPending(true);
    axios.post(`${api_url}/optional`, {
      name: name,
      phone: phone,
      email: email,
      module: selectedModule?.map(data => data.value).toString(),
      weight_release: selectedData?.filter(data => data.id == 0).map(data => data.value).toString(),
      acceptance: selectedData?.filter(data => data.id == 1).map(data => data.value).toString(),
      addiction: selectedData?.filter(data => data.id == 2).map(data => data.value).toString(),
      alcoholism: selectedData?.filter(data => data.id == 3).map(data => data.value).toString(),
      allergies: selectedData?.filter(data => data.id == 4).map(data => data.value).toString(),
      anger_issues: selectedData?.filter(data => data.id == 5).map(data => data.value).toString(),
      anxiety: selectedData?.filter(data => data.id == 6).map(data => data.value).toString(),
      bad_habits: selectedData?.filter(data => data.id == 7).map(data => data.value).toString(),
      bed_wetting: selectedData?.filter(data => data.id == 8).map(data => data.value).toString(),
      bereavement: selectedData?.filter(data => data.id == 9).map(data => data.value).toString(),
      blushing: selectedData?.filter(data => data.id == 10).map(data => data.value).toString(),
      body_confidence: selectedData?.filter(data => data.id == 11).map(data => data.value).toString(),
      body_dysmorphia: selectedData?.filter(data => data.id == 12).map(data => data.value).toString(),
      bruxism: selectedData?.filter(data => data.id == 13).map(data => data.value).toString(),
      confidence: selectedData?.filter(data => data.id == 14).map(data => data.value).toString(),
      claustrophobia: selectedData?.filter(data => data.id == 15).map(data => data.value).toString(),
      creating_new_habits: selectedData?.filter(data => data.id == 16).map(data => data.value).toString(),
      depression: selectedData?.filter(data => data.id == 17).map(data => data.value).toString(),
      drugs: selectedData?.filter(data => data.id == 18).map(data => data.value).toString(),
      eating_disorder: selectedData?.filter(data => data.id == 19).map(data => data.value).toString(),
      emetophobia: selectedData?.filter(data => data.id == 20).map(data => data.value).toString(),
      exercise: selectedData?.filter(data => data.id == 21).map(data => data.value).toString(),
      fears: selectedData?.filter(data => data.id == 22).map(data => data.value).toString(),
      feeling_safe_world: selectedData?.filter(data => data.id == 23).map(data => data.value).toString(),
      fibromyalgia: selectedData?.filter(data => data.id == 24).map(data => data.value).toString(),
      focus: selectedData?.filter(data => data.id == 25).map(data => data.value).toString(),
      forgiveness: selectedData?.filter(data => data.id == 26).map(data => data.value).toString(),
      grief: selectedData?.filter(data => data.id == 27).map(data => data.value).toString(),
      guilt: selectedData?.filter(data => data.id == 28).map(data => data.value).toString(),
      headaches: selectedData?.filter(data => data.id == 29).map(data => data.value).toString(),
      health_anxiety: selectedData?.filter(data => data.id == 30).map(data => data.value).toString(),
      living_abundant_life: selectedData?.filter(data => data.id == 31).map(data => data.value).toString(),
      ibs: selectedData?.filter(data => data.id == 32).map(data => data.value).toString(),
      insomnia_sleep: selectedData?.filter(data => data.id == 33).map(data => data.value).toString(),
      intrusive_thoughts: selectedData?.filter(data => data.id == 34).map(data => data.value).toString(),
      jealously: selectedData?.filter(data => data.id == 35).map(data => data.value).toString(),
      limiting_beliefs: selectedData?.filter(data => data.id == 36).map(data => data.value).toString(),
      memory_learning: selectedData?.filter(data => data.id == 37).map(data => data.value).toString(),
      menopause: selectedData?.filter(data => data.id == 38).map(data => data.value).toString(),
      migraines: selectedData?.filter(data => data.id == 39).map(data => data.value).toString(),
      misophonia: selectedData?.filter(data => data.id == 40).map(data => data.value).toString(),
      motivation: selectedData?.filter(data => data.id == 41).map(data => data.value).toString(),
      nail_biting: selectedData?.filter(data => data.id == 42).map(data => data.value).toString(),
      nightmares_night_terrors: selectedData?.filter(data => data.id == 43).map(data => data.value).toString(),
      panic_attacks: selectedData?.filter(data => data.id == 44).map(data => data.value).toString(),
      pain_relief: selectedData?.filter(data => data.id == 45).map(data => data.value).toString(),
      performance: selectedData?.filter(data => data.id == 46).map(data => data.value).toString(),
      phobias: selectedData?.filter(data => data.id == 47).map(data => data.value).toString(),
      positivity: selectedData?.filter(data => data.id == 48).map(data => data.value).toString(),
      procrastination: selectedData?.filter(data => data.id == 49).map(data => data.value).toString(),
      public_speaking: selectedData?.filter(data => data.id == 50).map(data => data.value).toString(),
      regret: selectedData?.filter(data => data.id == 51).map(data => data.value).toString(),
      relationships: selectedData?.filter(data => data.id == 52).map(data => data.value).toString(),
      restless_leg_syndrome: selectedData?.filter(data => data.id == 53).map(data => data.value).toString(),
      self_awareness: selectedData?.filter(data => data.id == 54).map(data => data.value).toString(),
      self_esteem: selectedData?.filter(data => data.id == 55).map(data => data.value).toString(),
      sexuality: selectedData?.filter(data => data.id == 56).map(data => data.value).toString(),
      sexual_disfunction: selectedData?.filter(data => data.id == 57).map(data => data.value).toString(),
      shaking_hands: selectedData?.filter(data => data.id == 58).map(data => data.value).toString(),
      shame: selectedData?.filter(data => data.id == 59).map(data => data.value).toString(),
      shopping_confidence: selectedData?.filter(data => data.id == 60).map(data => data.value).toString(),
      sleep_apnoea: selectedData?.filter(data => data.id == 61).map(data => data.value).toString(),
      snoring: selectedData?.filter(data => data.id == 62).map(data => data.value).toString(),
      social_anxiety: selectedData?.filter(data => data.id == 63).map(data => data.value).toString(),
      sports_performance: selectedData?.filter(data => data.id == 64).map(data => data.value).toString(),
      stammering: selectedData?.filter(data => data.id == 65).map(data => data.value).toString(),
      stress_relief_management: selectedData?.filter(data => data.id == 66).map(data => data.value).toString(),
      ticks: selectedData?.filter(data => data.id == 67).map(data => data.value).toString(),
      tinnitus: selectedData?.filter(data => data.id == 68).map(data => data.value).toString(),
      trauma: selectedData?.filter(data => data.id == 69).map(data => data.value).toString(),
      trichotillomania: selectedData?.filter(data => data.id == 70).map(data => data.value).toString(),
      tnx_id: '',
      amount: '',


    }, {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notify();
        setIsPay(false);
        setPending(false);
        setAmount('')
        window.location.reload(false);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  }
  return (
    <>
      <article className='_control_system'>
        <ToastContainer />
        <div className='title text-center'>
          <img style={{ borderRadius: "50%" }} src={`${api_url}/assets/logo/${logo}`} width={100} height={99} alt="image" />
          <h1 className='text-center pb-5'>THE PATHWAY HYPNOTHERAPY ROOMS</h1>
        </div>
        <div className='content-d pb-5'>
          <h1 className='heading'>Optional modules & Happiness Programme Details</h1>
          <p className='content'>Using hypnosis and your subconscious to release weight and developing positive thought patterns leading to the future you want.</p>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Programme Choices</TableCell>
                <TableCell align="right">Core Modules</TableCell>
                <TableCell align="right">Optional Modules</TableCell>
                <TableCell align="right">Price for programme</TableCell>
                <TableCell align="right">Approximate Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                  <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


        <div className='row'>
          <div className='col-6 pt-4'>
            <span>1 Module Programme – £1625</span>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Week Number</TableCell>
                    <TableCell align="right">Session</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 1-30 (6 weekly sessions/6 monthly sessions)
                    </TableCell>
                    <TableCell align="right">
                      Module 1: Weight Release Programme (Core)
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className='col-6 pt-4'>
            <span>3 Module Programme – £2725</span>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Week Number</TableCell>
                    <TableCell align="right">Session</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 1-30 (6 weekly sessions/ 6 monthly sessions)
                    </TableCell>
                    <TableCell align="right">
                      Core Module 1: Weight Release Programme
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 7-9
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 2: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 11-13
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 3: Client’s Choice
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className='col-6 pt-4'>
            <span>4 Module Programme – £3275</span>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Week Number</TableCell>
                    <TableCell align="right">Session</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 1-30 (6 weekly sessions/6 monthly sessions)
                    </TableCell>
                    <TableCell align="right">
                      Core Module 1: Weight Release Programme
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 7-9
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 2: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 11-13
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 3: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 15-17
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 4: Client’s Choice
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className='col-6 pt-4'>
            <span>8 Module Programme – £5475</span>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Week Number</TableCell>
                    <TableCell align="right">Session</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 1-30 (6 weekly sessions/6 monthly sessions)
                    </TableCell>
                    <TableCell align="right">
                      Core Module 1: Weight Release Programme
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 7-9
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 2: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 11-13
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 3: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 15-17
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 4: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 19-21
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 5: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 23-25
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 6: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 27-29
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 7: Client’s Choice
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Week 31-33
                    </TableCell>
                    <TableCell align="right">
                      Optional Module 8: Client’s Choice
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className='mt-4'>
          <span>For optional modules, please see the list below.  If there is something that is not listed that you would like to address during the programme, please let us know, as the approach used works for any unwanted thought patterns of behaviours.</span>
        </div>


        <div className='pb-4'>
          <h4>Module Options</h4>
          <form id="contactForm" className='row m-0' onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group col-4">
              <label>Name</label>
              <input type="text" id="name" className="form-control" placeholder="Name"
                {...register('name', {
                  required: true,
                  onChange: (e) => setName(e.target.value)
                })}
              />
              {errors.name && errors.name.type && <div className="help-block with-errors">Please Enter Name</div>}
            </div>

            <div className="form-group col-4">
              <label>Email</label>
              <input type="email" id="email" className="form-control" placeholder="Email"
                {...register('email', {
                  required: true,
                  onChange: (e) => setEmail(e.target.value)
                })}
              />
              {errors.email && errors.email.type && <div className="help-block with-errors">Please Enter Email</div>}
            </div>

            <div className="form-group col-4">
              <label>Phone</label>
              <input type="text" id="phone" className="form-control" placeholder="Phone"
                {...register('number', {
                  required: true,
                  onChange: (e) => setPhone(e.target.value)
                })}

              />
              {errors.number && errors.number.type && <div className="help-block with-errors">Please Enter Phone Number</div>}
            </div>

            <div>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Select Your Module</TableCell>
                      <TableCell align="center">
                        {['3 Module Programme', '5 Module Programme', '6 Module Programme', '10 Module Programme'].map((item) => {
                          return (
                            <FormControlLabel
                              value={item}
                              control={<Checkbox />}
                              label={item}
                              color="success"
                              labelPlacement='end'
                              onChange={(e) => handleSelectedModule(e.target)}
                            />
                          )
                        })}


                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Module Choices (C = Core / O = Optional)</TableCell>
                      <TableCell align="center">Optional module for the happiness program</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* <TableRow

                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Happiness Programm
                      </TableCell>
                      <TableCell align="center">
                        <FormControlLabel
                          value="3"
                          control={<Checkbox />}
                          label="3"
                          color="success"
                          labelPlacement="3"
                        />
                        <FormControlLabel
                          value="5"
                          control={<Checkbox />}
                          label="5"
                          color="success"
                          labelPlacement="5"
                        />
                        <FormControlLabel
                          value="6"
                          control={<Checkbox />}
                          label="6"
                          color="success"
                          labelPlacement="6"
                        />
                        <FormControlLabel
                          value="10"
                          control={<Checkbox />}
                          label="10"
                          color="success"
                          labelPlacement="10"
                        />
                      </TableCell>

                    </TableRow> */}

                    {programModules.map((row, index) => (
                      <TableRow
                        key={row}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row}
                        </TableCell>
                        <TableCell align="right">

                          {['3 Modules', '4 Modules', '8 Modules'].map((item) => {
                            return (
                              <FormControlLabel
                                value={item}
                                control={<Checkbox id={index} />}
                                label={item}
                                color="success"
                                labelPlacement='end'
                                onChange={(e) => handleSelectedData(e.target)}
                              />
                            )
                          })}

                          {/* <FormControlLabel
                            value="4 Modules"
                            control={<Checkbox />}
                            label="4 Modules"
                            color="success"
                            labelPlacement="4 Modules"
                          />
                          <FormControlLabel
                            value="8 Modules"
                            control={<Checkbox />}
                            label="8 Modules"
                            color="success"
                            labelPlacement="8 Modules"
                          /> */}

                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>


            <div className='col-md-1 form_btn_d m-3'>

              {isPay === false ?
                <Button type="submit" className='default-btn' style={{ backgroundColor: 'black' }}  >Submit
                  <i><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z"></path></svg></i>
                </Button> : null}

              {/* {isPay === true ?
                <Paypal client_Id={client_Id} amount={amount} onBooking={onSubmit} setTransactionId={setTransactionId} />
                : null} */}

            </div>

          </form>

        </div>




        <div className="copyright-area" style={{ backgroundColor: 'rgb(32 37 41)' }}>
          <div className="container">
            <div className="copyright-area-content">
              <p style={{ color: '#fff' }}>
                Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved

              </p>
            </div>
          </div>
        </div>
      </article>
    </>
  )
}

export default WeightReleaseProgramme