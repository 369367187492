import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube, FaPinterestP } from 'react-icons/fa';
import { Autoplay, Pagination, FreeMode } from "swiper/modules";
import { Link, useNavigate } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { seoData } from '../../../data';

const About = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [heroData, setHeroData] = useState([]);
    const [banner, setBanner] = useState('');
    const [about, setAbout] = useState([]);
    const [qualifications, setQualifications] = useState([])
    const [socialData, setSocialData] = useState([])
    const [pending, setPending] = useState(false)

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');
    const navigate = useNavigate();

    const getData = () => {
        axios.get(`${api_url}/getBanner/2`).then((response) => {

            setBanner(response?.data?.data[0]?.image)
            setHeroData(response?.data?.data)
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/getseo/2`).then((response) => {
            setSeoContent(response?.data?.data);

            setPending(true)
        }).catch((err) => {
            console.log(err);
            setPending(true)
        })

        axios.get(`${api_url}/getAdminAbout`).then((response) => {

            setAbout(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/adminProfile`).then((response) => {
            setSocialData(response?.data?.data)

            setPending(true)
        }).catch((err) => {
            console.log(err);
            setPending(true)
        })
        axios.get(`${api_url}/Qualification`).then((response) => {
            setQualifications(response?.data?.data)
            setPending(true)
        }).catch((err) => {
            console.log(err);
            setPending(true)
        })


    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [])


    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);


    // getBanner/$1

    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.about.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.about.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.about.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.about.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.about.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.about.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            {/* <div className="page-banner-area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-image">
                                <img data-cfsrc="assets/images/page-banner/page-banner-1.jpg" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/page-banner-1.jpg" alt="image" /></noscript>

                                <div className="image-shape">
                                    <img data-cfsrc="assets/images/page-banner/shape.png" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/shape.png" alt="image" /></noscript>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-content">
                                <h2>About Us</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-banner-shape">
                    <img data-cfsrc="assets/images/page-banner/banner-shape-1.png" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/banner-shape-1.png" alt="image" /></noscript>
                </div>
            </div> */}

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                <div className="container">
                    <div className="page-banner-content-two">
                        <h2>About Us</h2>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="about-area bg-ffffff ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        {about?.map((data, index) => {
                            return (
                                <>
                                    <div className="col-lg-6">
                                        <div className="about-main-content">
                                            <h1>About Us</h1>
                                            <div className="about-content-image">
                                                <h4 className="sub-title">{data.title}</h4>
                                            </div>
                                            {/*   <p>{data.description}</p> */}
                                            <HTMLRenderer html={data.description} />

                                            {/* <div className="about-btn">
                                                <Link to="about" className="default-btn">More About Us <i><FaUser /></i></Link>
                                            </div> */}

                                            <ul className="top-header-optional social_ text-start">
                                                <li className='d-flex gap-4'>
                                                    {socialData?.map((data) => {
                                                        return (
                                                            <>
                                                                <a href={data.facebook} target="_blank">
                                                                    <i><FaFacebookF /></i>
                                                                </a>
                                                                <a href={socialData[0].instagram} target="_blank">
                                                                    <i><FaInstagram /></i>
                                                                </a>
                                                                <a href={socialData[0].linkedIn} target="_blank">
                                                                    <i><FaLinkedinIn /></i>
                                                                </a>
                                                                <a href={socialData[0].twitter} target="_blank">
                                                                    <i><FaTwitter /></i>
                                                                </a>
                                                                <a href={socialData[0].youtube} target="_blank">
                                                                    <i><FaYoutube /></i>
                                                                </a>
                                                            </>
                                                        )
                                                    })}


                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="about-main-image">
                                            <img src={`${api_url}/assets/about/${data.image}`} alt="image" />
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </section>


            <Swiper
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={30}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay, FreeMode]}
                className="qualification_swiper pb-5"
                breakpoints={{
                    30: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}

            >

                {qualifications?.map((data) => {
                    return (
                        <>
                            <SwiperSlide>
                                <img src={`${api_url}/assets/qualification/${data.image}`} alt='image' />
                            </SwiperSlide>
                        </>
                    )
                })}

            </Swiper>
        </>
    )
}

export default About