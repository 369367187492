import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'
import HTMLRenderer from 'react-html-renderer';
import Spinner from 'react-bootstrap/Spinner';

const PrivacyPolicy = () => {

    const api_url = process.env.REACT_APP_API_URL;


    const [data, setData] = useState([]);
    const [pending, setPending] = useState(false)
    const navigate = useNavigate();

    const getData = () => {

        axios.get(`${api_url}/getprivacy`).then((response) => {
    
            setData(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });


    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [])


    return (
        <>

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="blog-details-area" style={{ padding: "45px 0px" }}>
                <div className="container">
                    <div className="page-banner-content">

                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                Privacy Policy
                            </li>

                        </ul>
                    </div>
                    {data?.map((data) => {
                        return (
                            <>
                                <div className="blog-details-desc">
                                    <div className="article-content">
                                        <div className="title-box">
                                            <h2>Privacy Policy</h2>
                                        </div>

                                        <div className='row m-0'>

                                            <div className='col-12'>
                                                <HTMLRenderer html={data.privacy} />
                                                {/* <p>{blog.content}</p> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div >
            </div>



        </>
    )
}

export default PrivacyPolicy