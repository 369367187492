import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import axios from 'axios';
import { BiLoaderCircle } from 'react-icons/bi';
import { Button } from 'react-bootstrap';

const RelatedBlogs = ({blogs, title}) => {

  const api_url = process.env.REACT_APP_API_URL;

  return (
    <>
      <section className="blog-area" style={{ padding: "45px 0px" }}>
        <div className="container">
          <div className="section-title">
            <h2>{title}</h2>
          </div>

          <div className="row justify-content-center">
            {blogs?.map((blog) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="single-blog">
                    <div className="blog-image">
                      <Link to={`/blog/${blog.title.replace(/\s+/g, '-')}/${blog.id}`}><img src={`${api_url}/assets/blogs/${blog.image}`} alt="image" /></Link>
                      {/* , month: 'short' */}
                      <div className="tag">{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })}th <span>{new Date(blog.created_at).toLocaleDateString('en-En', { month: 'short' })}</span></div>
                    </div>

                    <div className="blog-content">
                      <h3>
                        <Link to={`/blog/${blog.title.replace(/\s+/g, '-')}/${blog.id}`}>{blog.title}</Link>
                      </h3>
                      <div className="blog-btn">
                        <Link to={`/blog/${blog.title.replace(/\s+/g, '-')}/${blog.id}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}


          </div>

        

        </div>
      </section>
    </>
  )
}

export default RelatedBlogs