
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import './App.css';
import Frontend from './Frontend';
import About from './components/pages/About';
import Blog from './components/pages/Blog';
import ContactUs from './components/pages/ContactUs';
import Home from './components/pages/Home';
import Shop from './components/pages/Shop';
import SingleBlog from './components/pages/SingleBlog';
import Therapies from './components/pages/Therapies';
import ThePathwayControlSystem from './components/pages/ThePathwayControlSystem';
import ThePathwayStill from './components/pages/ThePathwayStill';
import ClientReviews from './components/pages/ClientReviews';
import SingleTherapies from './components/pages/SingleTherapies';
import Product from './components/pages/Product';
import Checkout from './components/pages/Checkout';
import ClientContract from './components/forms/client-contract';
import Forms from './Forms';
import HappinessProgram from './components/forms/the-control-system-happiness-programme-details';
import ConfidentialForm from './components/forms/ConfidentialForm';
import StopSmoking from './components/forms/stop-smoking';
import WeightReleaseProgramme from './components/forms/weight-release-happiness-programme-details';
import WeighReleaseQuestionnaire from './components/forms/WeighReleaseQuestionnaire';
import Stepper from './components/common/stepper/Stepper';
// import Concessions from './components/pages/Concessions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Success from './components/common/stepper/Success';
import Error from './components/common/Error';
import HandleRouting from './components/common/HandleRouting/HandleRouting';

function App() {

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Frontend />}>
            <Route path='/' element={<Home />} />
            <Route exact path='/about-us' element={<About />} />
            <Route exact path='/the-control-system' element={<ThePathwayControlSystem />} />
            <Route exact path='/the-pathway-still' element={<ThePathwayStill />} />
            <Route exact path='/client-reviews' element={<ClientReviews />} />
            <Route exact path='/checkout' element={<Checkout />} />
            <Route exact path='/therapies' element={<Therapies />} />
            <Route exact path='/therapies/:name/:id' element={<SingleTherapies />} />
            <Route exact path='/therapies/hypnotherapyforsmoking/15' element={<HandleRouting />} />

            <Route exact path='/Success/:id' element={<Success />} />
            <Route exact path='/shop' element={<Shop />} />
            <Route exact path='/shop/:name/:id' element={<Product />} />
            <Route exact path='/blog' element={<Blog />} />
            {/* <Route exact path='/concessions' element={<Concessions />} /> */}
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='/appointment' element={<Stepper />} />
            <Route exact path='/blog/:name/:id' element={<SingleBlog />} />
            <Route exact path='/contact-us' element={<ContactUs />} />


            <Route exact path='/home' element={<HandleRouting />} />
            <Route exact path='/contact' element={<HandleRouting />} />
            <Route exact path='/events' element={<HandleRouting />} />
            <Route exact path='/concessions' element={<HandleRouting />} />

            <Route exact path='/m/login' element={<HandleRouting />} />
            <Route exact path='/m/noaccess' element={<HandleRouting />} />
            <Route exact path='/client-reviews/f/jordans-journey' element={<HandleRouting />} />
            <Route exact path='/home/f/jordans-journey-1' element={<HandleRouting />} />

            <Route exact path='/menopause' element={<HandleRouting />} />
            <Route path='/home/f/week-2-hypno-gastric-band-therapy' element={<HandleRouting />} />
            <Route exact path='/testimonials' element={<HandleRouting />} />
            
            <Route path='*' element={<Error />} />


          </Route>

          <Route exact path='/form' element={<Forms />}>
            <Route exact path='/form/1' element={<ClientContract />} />
            <Route exact path='/form/2' element={<ConfidentialForm />} />
            <Route exact path='/form/3' element={<StopSmoking />} />
            <Route exact path='/form/4' element={<HappinessProgram />} />
            <Route exact path='/form/5' element={<WeightReleaseProgramme />} />
            <Route exact path='/form/6' element={<WeighReleaseQuestionnaire />} />

          </Route>

        </Routes>
      </BrowserRouter>

    </React.Fragment >

  );
}

export default App;
