import React, { useLayoutEffect, useState, useEffect, useRef, useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { HiOutlineShoppingCart, HiPhone, HiMailOpen, HiLocationMarker, HiSearch } from 'react-icons/hi';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube, FaPinterestP, FaBars } from 'react-icons/fa';
import { AiOutlineShoppingCart, AiOutlineSchedule } from 'react-icons/ai';
import { RxTriangleDown } from 'react-icons/rx';
import { FaHandPointUp } from 'react-icons/fa';
import { FaPlus, FaMinus, FaTimes } from 'react-icons/fa';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import axios from 'axios';
import emptyCart from '../assets/emptyCart.jpg'
import { Badge } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';


const Header = ({ cart, subTotal, addToCart, removeFromCart }) => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [logo, setLogo] = useState('');
    const [therapiesMenu, setTherapiesMenu] = useState([]);
    const [productsMenu, setProductsMenu] = useState([]);
    const [qty, setQty] = useState(1)
    const [click, setClick] = React.useState(false);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);

    const location = useLocation();
    const hideCartIconOnPages = ['/checkout', '/success'];

    const shouldHideCartIcon = () => {
        return hideCartIconOnPages.includes(location.pathname);
    };



    const getData = () => {
        axios.get(`${api_url}/adminProfile`).then((response) => {

            setLogo(response?.data?.data[0]?.logo)
            setData(response?.data?.data)
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`${api_url}/getmenutherapies`).then((response) => {

            // setLogo(response?.data?.data[0]?.logo)
            setTherapiesMenu(response?.data?.data)
        }).catch((err) => {
            console.log(err);
        })
        axios.get(`${api_url}/getmenuProduct`).then((response) => {

            // setLogo(response?.data?.data[0]?.logo)
            setProductsMenu(response?.data?.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const ref = useRef();

    const toggleCart = () => {
        if (ref.current.classList.contains('translate_x_full')) {
            ref.current.classList.remove('translate_x_full')
            ref.current.classList.add('translate_x_')

        } else if (!ref.current.classList.contains('translate_x_full')) {
            ref.current.classList.add('translate_x_full')
            ref.current.classList.remove('translate_x_')

        }
    }


    // useEffect(() => {
    //     // toggleCart();
    // }, [cart])


    // const increment = () => {
    //     let newQty = qty + 1;
    //     // let newPrice = parseInt(price)

    //     handleCart(id, name, newQty, price)
    //     setQty(newQty)
    // }

    // const decrement = () => {
    //     let newQty = qty > 1 ? qty - 1 : 1
    //     // let newPrice = parseInt(price)

    //     handleCart(id, service, newPrice, newCount)
    //     setQty(newQty)
    // }


    // sticky header
    const headerStickyRef = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `main-navbar ${(sticky && check) ? 'sticky' : ''}`
    const headerResponsiveClasses = `main-responsive-nav ${(sticky && check) ? 'sticky' : ''}`


    const { clientHeight } = ref;

    const checkChange = (value) => {
        setCheck(value);
    };


    function useStickyHeader(offset = 0) {
        const [stick, setStick] = useState(false);

        const handleScroll = () => {
            setStick(window.scrollY > offset);
        };

        useLayoutEffect(() => {
            window.addEventListener('scroll', handleScroll);

            return (() => {
                window.removeEventListener('scroll', handleScroll);
            });
        });

        return stick;
    }




    return (
        <>



            {/* <div className="header-information">Header Information</div> */}

            <div className="top-header-area">
                <div className="container">
                    <div className="row align-items-center">
                        {data?.map((data) => {
                            return (
                                <>
                                    <div className="col-lg-9 col-md-12">
                                        <ul className="top-header-information">
                                            <li>
                                                <a href={`tel:${data.phone}`}>
                                                    <i><HiPhone /></i>
                                                    {data.phone}</a>
                                            </li>

                                            <li>
                                                <a href={`mailto:glen@thepathwayhypnotherapyrooms.co.uk`}><i><HiMailOpen /></i>
                                                    <span className="__cf_email__" >glen@thepathwayhypnotherapyrooms.co.uk</span></a>
                                            </li>

                                            <li>
                                                <a href="https://goo.gl/maps/PKUSRk8zNxwDHfwn7" target="_blank"><i><HiLocationMarker /></i>
                                                    {data.address}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-3 col-md-12">
                                        <ul className="top-header-optional">
                                            <li>
                                                <a href={data.facebook} target="_blank">
                                                    <i><FaFacebookF /></i>
                                                </a>
                                                <a href={data.instagram} target="_blank">
                                                    <i><FaInstagram /></i>
                                                </a>
                                                <a href={data.linkedIn} target="_blank">
                                                    <i><FaLinkedinIn /></i>
                                                </a>
                                                <a href={data.twitter} target="_blank">
                                                    <i><FaTwitter /></i>
                                                </a>
                                                <a href={data.youtube} target="_blank">
                                                    <i><FaYoutube /></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </div>

            <div className="navbar-area">
                <div className={headerResponsiveClasses} ref={headerStickyRef}>
                    <div className="container">
                        <div className="main-responsive-menu d-flex justify-content-between align-items-center">
                            <div className="logo">
                                <NavLink to="/">
                                    <img style={{ width: "100px" }} src={`${api_url}/assets/logo/${logo}`} alt="logo" />
                                </NavLink>
                            </div>
                            <div className='d-flex align-align-items-center gap-4'>
                                {!shouldHideCartIcon() &&
                                    <div className="option-item">
                                        <div className="cart-btn" onClick={toggleCart} style={{ cursor: 'pointer' }}>
                                            <Badge badgeContent={cart?.length} color="primary" showZero>
                                                <span to="cart" style={{ cursor: 'pointer' }}>
                                                    <i><AiOutlineShoppingCart style={{ fontSize: '24px' }} /></i>
                                                </span>
                                            </Badge>
                                        </div>
                                    </div>}
                                <div className="option-item">
                                    <div className="cart-btn">
                                        <NavLink end to="/appointment">
                                            <AiOutlineSchedule style={{ fontSize: '30px' }} />
                                        </NavLink>

                                    </div>
                                </div>

                                <div className='_bar_icon' onClick={() => handleClick()} >
                                    {click ? <FaTimes style={{ fontSize: '30px' }} /> : <FaBars style={{ fontSize: '30px' }} />}
                                </div>
                            </div>

                        </div>

                        {/* <div className="option-item m-0" style={(sticky && check) ? { opacity: '1' } : { opacity: '0' }}>
                            <div className="cart-btn" style={{ position: "absolute", top: '150%', right: '0' }}>
                                <ul className='d-flex flex-column gap-2' style={{ listStyleType: "none" }}>
                                    <li>
                                        <a className='d-flex contact_icons' href={`tel:${data[0]?.phone}`}><HiPhone style={{ fontSize: '24px' }} /></a>
                                    </li>
                                    <li>
                                        <a className='d-flex contact_icons' href={`mailto: ${data[0]?.email}`}><HiMailOpen style={{ fontSize: '24px' }} /></a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className={click ? "_hide_" : "_show_"} id="navbarSupportedContent">
                            <div className='container'>
                                <ul className="navbar-nav m-auto">
                                    <li className='nav-item'>
                                        <NavLink to="/" className="nav-link" onClick={() => handleClick()}>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        {/* <span style={{ cursor: 'pointer' }} className={window.location.hash.includes("#/about") || window.location.hash.includes("#/client-reviews") ? "nav-link active" : "nav-link"} >
                                            About
                                            <i><RxTriangleDown style={{ fontSize: '20px' }} /></i>
                                        </span> */}



                                        <NavLink to="about-us" className="nav-link" onClick={() => handleClick()}>
                                            About Us
                                        </NavLink>

                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink to="about" className="nav-link">
                                                    About Us
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink to="client-reviews" className="nav-link">Client Reviews</NavLink>
                                            </li>
                                        </ul> */}
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="the-control-system" className="nav-link" onClick={() => handleClick()}>
                                            The Control System
                                        </NavLink>

                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/therapies" className="nav-link" onClick={() => handleClick()}>
                                            Therapies
                                            {/* <i><RxTriangleDown style={{ fontSize: '20px' }} /></i> */}
                                        </NavLink>

                                        {/* <ul className="dropdown-menu">
                                            {therapiesMenu?.map((data) => {
                                                return (
                                                    <li className="nav-item">
                                                        <NavLink to={`/therapies/${data.id}`} className="nav-link">{data.title}</NavLink>
                                                    </li>
                                                )
                                            })}
                                           

                                            <li className="nav-item">
                                                <div className="option-item">
                                                    <div className="navbar-btn">
                                                        <Link to="/concessions" className="default-btn">Concessions<i><FaHandPointUp /></i></Link>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul> */}
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/the-pathway-still" className="nav-link" onClick={() => handleClick()}>
                                            The Pathway Still
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/shop" className="nav-link" onClick={() => handleClick()}>
                                            Hypnosis Recordings
                                            {/* <i><RxTriangleDown style={{ fontSize: '20px' }} /></i> */}
                                        </NavLink>

                                        {/* <ul className="dropdown-menu">
                                            {productsMenu?.map((data) => {
                                                return (
                                                    <li className="nav-item">
                                                        <NavLink to={`/shop/${data.id}`} className="nav-link">{data.name}</NavLink>
                                                    </li>
                                                )
                                            })}
                                        </ul> */}
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="blog" className="nav-link" onClick={() => handleClick()}>Blog</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="contact" className="nav-link" onClick={() => handleClick()}>Contact Us</NavLink>
                                    </li>

                                </ul>
                            </div>




                            {/* <div className="others-options d-flex align-items-center">

                                <div className="option-item m-0">
                                    <div className="cart-btn">
                                        <ul className='d-flex'>
                                            <li>
                                                <a className='contact_icons' href={`tel:${data[0]?.phone}`}><i><HiPhone style={{ fontSize: '24px' }} /></i></a>
                                            </li>
                                            <li>
                                                <a className='contact_icons' href={`mailto: ${data[0]?.email}`}><i><HiMailOpen style={{ fontSize: '24px' }} /></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {window.location.hash.includes("#/checkout") === true ? null :
                                    <div className="option-item">

                                        <div className="cart-btn" onClick={toggleCart} style={{ cursor: 'pointer' }}>
                                            <Badge badgeContent={cart?.length} color="primary" showZero>
                                                <span to="cart" style={{ cursor: 'pointer' }}>
                                                    <i><AiOutlineShoppingCart style={{ fontSize: '24px' }} /></i>
                                                </span>
                                            </Badge>
                                        </div>
                                    </div>
                                }
                                <div className="option-item">
                                    <div className="navbar-btn">
                                        <Link to="/appointment" className="default-btn">Appointment<i><BsFillHandIndexThumbFill /></i></Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className={headerClasses} ref={headerStickyRef}>
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <NavLink to="/" end>
                                <img style={{ width: "100px" }} src={`${api_url}/assets/logo/${logo}`} alt="logo" />
                            </NavLink>
                            {/* </Link> */}

                            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul className="navbar-nav m-auto">
                                    {/* <li className="nav-item">
                                        <NavLink to="/" className="nav-link active">
                                            HOME
qty
                                        </NavLink>
                                    </li> */}


                                    <li className="nav-item">
                                        <span style={{ cursor: 'pointer' }} className={window.location.hash.includes("#/about") || window.location.hash.includes("#/client-reviews") ? "nav-link active" : "nav-link"} >
                                            About
                                            <i><RxTriangleDown style={{ fontSize: '20px' }} /></i>
                                        </span>


                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink to="about-us" className="nav-link">
                                                    About Us
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink to="client-reviews" className="nav-link">Client Reviews</NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="the-control-system" className="nav-link">
                                            The Control System
                                        </NavLink>

                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/therapies" className="nav-link" >
                                            Therapies
                                            <i><RxTriangleDown style={{ fontSize: '20px' }} /></i>
                                        </NavLink>

                                        <ul className="dropdown-menu">
                                            {therapiesMenu?.map((data) => {
                                                return (
                                                    <li className="nav-item">
                                                        <NavLink to={`/therapies/${data.title.replace(/\s/g, '').toLowerCase()}/${data.id}`} className="nav-link">{data.title}</NavLink>
                                                    </li>
                                                )
                                            })}
                                            {/* <li className="nav-item">
                                                <Link to="/therapies/1" className="nav-link">Menopause</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/therapies/2" className="nav-link">Weight Release Therapy</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/therapies/3" className="nav-link">Hypno-Gastric Band Therapy</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/therapies/1" className="nav-link">Hypnobirthing & Pregnancy</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/therapies/2" className="nav-link">Hypnotherapy For Stopping/Quit Smoking</Link>
                                            </li> */}

                                            {/* <li className="nav-item">
                                                <div className="option-item">
                                                    <div className="navbar-btn">
                                                        <Link to="/concessions" className="default-btn">Concessions<i><FaHandPointUp /></i></Link>
                                                    </div>
                                                </div>
                                            </li> */}

                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="the-pathway-still" className="nav-link">
                                            The Pathway Still
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="shop" className="nav-link">
                                            Hypnosis Recordings
                                            <i><RxTriangleDown style={{ fontSize: '20px' }} /></i>
                                        </NavLink>

                                        <ul className="dropdown-menu">
                                            {productsMenu?.map((data) => {
                                                return (
                                                    <li className="nav-item">
                                                        <NavLink to={`/shop/${data.name.replace(/\s/g, '').toLowerCase()}/${data.id}`} className="nav-link">{data.name}</NavLink>
                                                    </li>
                                                )
                                            })}
                                            {/* <li className="nav-item">
                                                <Link to="/shop/1" className="nav-link">Relaxation Recording</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/shop/2" className="nav-link">Anxiety Reduction</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/shop/3" className="nav-link">Tension and Anxiety Recording</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/shop/4" className="nav-link">Relaxation Taster</Link>
                                            </li> */}



                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="blog" className="nav-link">Blog</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="contact-us" className="nav-link">Contact Us</NavLink>
                                    </li>

                                </ul>



                                <div className="others-options d-flex align-items-center">

                                    {/* <div className="option-item m-0" style={(sticky && check) ? { opacity: '1' } : { opacity: '0' }}>
                                        <div className="cart-btn" style={{ position: "absolute", top: '150%', right: '0' }}>
                                            <ul className='d-flex flex-column gap-2'>
                                                <li>
                                                    <a className='d-flex contact_icons' href={`tel:${data[0]?.phone}`}><HiPhone style={{ fontSize: '30px' }} /></a>
                                                </li>
                                                <li>
                                                    <a className='d-flex contact_icons' href={`mailto: ${data[0]?.email}`}><HiMailOpen style={{ fontSize: '30px' }} /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}

                                    {!shouldHideCartIcon() &&
                                        <div className="option-item">

                                            <div className="cart-btn" onClick={toggleCart} style={{ cursor: 'pointer' }}>
                                                <Badge badgeContent={cart?.length} color="primary" showZero>
                                                    <span to="cart" style={{ cursor: 'pointer' }}>
                                                        <i><AiOutlineShoppingCart style={{ fontSize: '24px' }} /></i>
                                                    </span>
                                                </Badge>
                                            </div>
                                        </div>
                                    }

                                    {/* <div className="option-item">
                                        <div className="search-box">
                                            <i><HiSearch /></i>
                                        </div>
                                    </div> */}

                                    <div className="option-item">
                                        <div className="navbar-btn">
                                            <Link to="/appointment" className="default-btn">Appointment<i><BsFillHandIndexThumbFill /></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='_bar_icon'>
                                <FaBars style={{fontSize: '30px'}}  />
                            </div> */}

                        </nav>
                    </div>
                </div>
                {/* 
                <div className="others-option-for-responsive">
                    <div className="container">
                        <div className="dot-menu">
                            <div className="inner">
                                <div className="circle circle-one"></div>
                                <div className="circle circle-two"></div>
                                <div className="circle circle-three"></div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="option-inner">
                                <div className="others-options d-flex align-items-center">
                                    <div className="option-item">
                                        <div className="cart-btn">
                                            <Link to="cart">
                                                <i className='flaticon-shopping-cart'></i>
                                                <span>2</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="option-item">
                                        <div className="search-box">
                                            <i className='flaticon-search'></i>
                                        </div>
                                    </div>

                                    <div className="option-item">
                                        <div className="navbar-btn">
                                            <a href="#" className="default-btn">Book Online <i><FaHandPointUp /></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


            <div ref={ref} id="scroll_" className='sideCart translate_x_full position-fixed top-0 h-100 p-4' style={{ right: "0", background: "#f9faff", zIndex: '1000', overflow: 'auto' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className='d-flex justify-content-between align-items-center pb-4'>
                            <h3>Shopping cart</h3> <span onClick={toggleCart}><FaTimes style={{ fontSize: '24px', cursor: 'pointer' }} /></span>
                        </div>

                        <form>
                            <div id="scroll_" className="cart-table table-responsive _cart-s" style={{ height: '250px', overflowY: 'auto' }}>
                                <table className="table table-bordered">
                                    <tbody>

                                        {cart?.length == 0 ? <>
                                            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
                                                <img src={emptyCart} width={184} height={184} alt="image" />
                                                <h5>No Product Found</h5>
                                            </div>
                                        </> : null}

                                        {cart?.map((item) => {
                                            return (
                                                <tr>
                                                    <td className="product-thumbnail">
                                                        <Link to={`/shop/${item.id}`}>
                                                            {/* <Badge badgeContent={item.qty} color="primary"> */}
                                                            <img src={`${api_url}/assets/product/${item.image}`} alt="image" />
                                                            {/* </Badge> */}
                                                        </Link>
                                                    </td>
                                                    <td className="product-name">
                                                        <Link to={`/shop/${item.id}`}>{item.name}</Link><br></br>
                                                        <span className="product-price">
                                                            <span className="unit-amount"> &#163;{item.price}
                                                                {/* x {item.qty} */}
                                                            </span>
                                                        </span>

                                                    </td>

                                                    <td className='d-flex'>
                                                        <Button className='bg-transparent border-0 text-black' onClick={() => removeFromCart(item.id)} ><FaTimes /></Button>
                                                    </td>

                                                    {/* <td className="product-quantity">
                                                        <div className="input-counter">
                                                            <span className="minus-btn"
                                                                onClick={() => removeFromCart(item.id)}
                                                            >
                                                                <FaMinus />
                                                            </span>
                                                            <input type="text" value={item.qty} />
                                                            <span className="plus-btn"
                                                                onClick={() => addToCart(item.id, item.name, 1, item.price)}
                                                            >
                                                                <FaPlus />
                                                            </span>
                                                        </div>
                                                    </td> */}

                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>

                            {/* <div className="cart-buttons">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-sm-7 col-md-7">
                                        <div className="shopping-coupon-code">
                                            <input type="text" className="form-control" placeholder="Enter Coupon Code" name="coupon-code" id="coupon-code" />
                                            <button type="submit" className="default-btn">Apply Coupon <i className="flaticon-right-arrow"></i></button>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-sm-5 col-md-5">
                                        <a href="#" className="default-btn">Continue Shopping <i className="flaticon-right-arrow"></i></a>
                                    </div>
                                </div>
                            </div> */}

                            <div className="cart-totals">
                                <h3>Cart Total</h3>

                                <ul>
                                    <li>
                                        Sub Total
                                        <span><b> £{subTotal}</b></span>
                                    </li>

                                    <li>
                                        Total
                                        <span><b> £{subTotal}</b></span>
                                    </li>
                                </ul>
                                {cart?.length > 0 ?
                                    <div className="totals-btn">
                                        <Link onClick={toggleCart} to="/checkout" className="default-btn">Proceed To Checkout <i><AiOutlineShoppingCart /></i></Link>
                                    </div> : null}
                            </div>
                        </form>
                    </div>
                </div>

            </div>


            {/* <div className="search-overlay">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>

                        <div className="search-overlay-close">
                            <span className="search-overlay-close-line"></span>
                            <span className="search-overlay-close-line"></span>
                        </div>

                        <div className="search-overlay-form">
                            <form>
                                <input type="text" className="input-search" placeholder="Search here..." />
                                <button type="submit"><i className='flaticon-search'></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}



        </>
    )
}

export default Header