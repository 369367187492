import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HandleRouting = () => {

    const navigate = useNavigate();

    const shouldRedirect = true;

    useEffect(() => {

        let targetRoute = '';

        const currentUrl = window.location.href;

        if (currentUrl.includes('/home')) {
            targetRoute = '/'
        }

        if (currentUrl.includes('/contact')) {
            targetRoute = '/contact-us'
        }

        if (currentUrl.includes('/about')) {
            targetRoute = '/about-us'
        }

        if (currentUrl.includes('/events')) {
            targetRoute = '/blog'
        }


        if (currentUrl.includes('/concessions')) {
            targetRoute = '/appointment'
        }

        if (currentUrl.includes('/m/login')) {
            targetRoute = '/'
        }

        if (currentUrl.includes('/m/noaccess')) {
            targetRoute = '/'
        }

        if (currentUrl.includes('/client-reviews/f/jordans-journey')) {
            targetRoute = '/client-reviews'
        }

        if (currentUrl.includes('/home/f/jordans-journey-1')) {
            targetRoute = '/client-reviews'
        }

        if (currentUrl.includes('/menopause')) {
            targetRoute = '/therapies/menopause/10'
        }

        if (currentUrl.includes('/home/f/week-2-hypno-gastric-band-therapy')) {
            targetRoute = '/therapies/hypno-gastricbandtherapy/3'
        }

        if (currentUrl.includes('/testimonials')) {
            targetRoute = '/client-reviews'
        }

        if (currentUrl.includes('/therapies/hypnotherapyforsmoking/15')) {
            targetRoute = '/therapies/hypnotherapyforstopsmoking/15'
        }
        // 
        navigate(targetRoute);

    }, [navigate, shouldRedirect]);

    return (
        <div>HandleRouting</div>
    )
}

export default HandleRouting