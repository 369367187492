import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs';
import { BiLoaderCircle } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import bannerNew from '../assets/banner.png';
import HTMLRenderer from 'react-html-renderer';
import Video1 from '../assets/Skype_Video.mp4'
import { Helmet } from "react-helmet";
import { seoData } from '../../../data';

const Shop = () => {

    const api_url = process.env.REACT_APP_API_URL;


    const [addToCart] = useOutletContext();
    const [products, setProducts] = useState([]);
    // const [searchedProducts, setSearchedProducts] = useState([]);
    const [heroData, setHeroData] = useState([]);
    const [banner, setBanner] = useState('');
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState('');
    const [dataPerPage, setDataPerPage] = useState(4);
    const [curProducts, setCurProducts] = useState([])
    const [pending, setPending] = useState(false)
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');
    const getData = () => {

        axios.get(`${api_url}/getBanner/7`).then((response) => {

            setBanner(response?.data?.data[0]?.image)
            setHeroData(response?.data?.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/9`).then((response) => {
            setSeoContent(response?.data?.data);

        }).catch((err) => {
            console.log(err);
        })

        axios.get(`${api_url}/getAllProduct`).then((response) => {

            setProducts(response?.data?.data);
            setCurProducts(response?.data?.data)
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    // console.log(JSON.parse(localStorage.getItem("myCart")))

    const addTo = (id, name, qty, price, image) => {
        addToCart(id, name, qty, price, image)

    }


    const handleOrder = (order) => {
        setOrder(order);
        searchFilter(search, order)
    }

    const searchFilter = (query, order) => {

        let sortedData = [...products]

        if (query != "") {
            if (order == "desc") {
                sortedData = sortedData?.filter((data) => data.name.toLowerCase().includes(query))?.sort((a, b) => b.price - a.price)
            } else if (order == "asc") {
                sortedData = sortedData?.filter((data) => data.name.toLowerCase().includes(query))?.sort((a, b) => a.price - b.price)
            } else {
                sortedData = sortedData?.filter((data) => data.name.toLowerCase().includes(query))
            }
            // sortedData = products?.filter((data) => data.name.toLowerCase().includes(query))
            // console.log(query, sortedData)
        } else {
            if (order == "desc") {
                sortedData?.sort((a, b) => b.price - a.price)
            } else if (order == "asc") {
                sortedData?.sort((a, b) => a.price - b.price)
            } else {
                sortedData = sortedData
            }
            // console.log(order, sortedData)
        }

        let newData = sortedData

        // console.log(newData)

        setCurProducts(newData)
    }

    // var book = products?.find(product => product.title.toLowerCase() == input.value.toLowerCase())

    // console.log(products?.find(product => product.name.toLowerCase() == search.toLowerCase()))
    // console.log(JSON.parse(localStorage.getItem("myCart")))
    // console.log(products?.sort((a, b) => b.price - a.price))
    // console.log(products?.sort((a, b) => a.price - b.price))
    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.shop.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.shop.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.shop.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.shop.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.shop.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.shop.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>


            {/* <div className="page-banner-area">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="page-banner-image">
                            <img src="https://thepathwayhypnotherapyrooms.com/wp-content/uploads/2022/09/image_2022_09_16T11_44_50_785Z.png" alt="image" style={{ display: "block", visibility: "visible" }} />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="page-banner-content">
                            <h2>Products Grid</h2>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>Products Grid</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner-shape">
                <img data-cfsrc="assets/images/page-banner/banner-shape-1.png" alt="image" style={{ display: "none", visibility: "hidden" }} />
            </div>
        </div> */}

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                <div className="container">
                    <div className="page-banner-content-two">
                        <h1>Hypnosis Recordings</h1>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Hypnosis Recordings</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="offer-area mt-5"
                style={{ backgroundImage: `url(${bannerNew})` }}
            >
                <div className="container-fluid">
                    <div className="row align-items-center">
                        {/* {controlSystem?.map((data, index) => {

              return (
                <> */}
                        {/* 12 */}
                        <div className="col-lg-6">
                            {/* m-auto */}
                            <div className="offer-item">
                                <div className="content">
                                </div>
                                {/* <h4 style={{ color: '#fff' }} className="sub-title">What can I use CONTROL for
                      </h4> */}
                                <p style={{ color: '#fff' }}>
                                    Welcome to The Pathway Hypnotherapy Rooms’ Hypnosis Recordings page.  Here you are able to purchase and download hypnosis recordings. You can then listen to them as many times as you like whenever you need to. They are designed to support with a variety of issues including anxiety, stress and tension.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="offer-image">
                                {/* <img className='p-5' src={`assets/hypnosis/${data.image}`} alt="image" /> */}
                                <video className="w-100"
                                    src={Video1}
                                    controls />
                            </div>
                        </div>

                        {/* <div className="col-lg-6">
                <div className="offer-image">
                  <img className='p-5' src={`assets/hypnosis/`} alt="image" />
                </div>
              </div> */}
                        {/* </>
              )
            })} */}

                    </div>
                </div>
            </section>

            <section className="products-area ptb-100">
                <div className="container">
                    <div className="psylo-grid-sorting row align-items-center">
                        <div className="col-lg-4 col-md-6">
                            <form className="search-form">
                                <input type="search" className="search-field" placeholder="Search..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />

                                <button type="button"
                                    onClick={() => searchFilter(search, order)}
                                ><i><BsSearch /></i></button>
                            </form>
                        </div>

                        <div className="col-lg-8 col-md-6 ordering">
                            <div className="select-box">
                                <label>Sort By:</label>
                                <Form.Select aria-label="Default select example" value={order} onChange={(e) => handleOrder(e.target.value)}>
                                    <option value="">Default</option>
                                    <option value="asc">Price: low to high</option>
                                    <option value="desc">Price: high to low</option>
                                </Form.Select>
                                {/* <select onChange={(e) => searchFilter(e.target.value)}>
                                    <option value="">Default</option>
                                    <option>Popularity</option>
                                    <option>Latest</option>
                                    <option value="asc">Price: low to high</option>
                                    <option value="desc">Price: high to low</option>
                                </select> */}
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 text-center">
                        {curProducts?.slice(0, dataPerPage).map((product) => {



                            return (
                                <div className="col-lg-3 col-md-6">
                                    <div className="products-item">
                                        <div className="products-image">


                                            <Link to={`/shop/${product.id}/${product.name.replace(/\s/g, '').toLowerCase()}`}><img src={`${api_url}/assets/product/${product.image}`} alt="image" style={{ display: "block", visibility: "visible" }} /></Link>

                                            {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == product.id) === false ?
                                                <div className="action-btn">
                                                    <button onClick={() => addToCart(product.id, product.name, 1, product.price, product.image)} className="default-btn">Add To Cart<i><AiOutlineShoppingCart /></i></button>
                                                </div>

                                                : <div className="action-btn">
                                                    <button className="default-btn"
                                                        disabled
                                                    >Added to Cart<i><AiOutlineShoppingCart /></i></button>
                                                </div>
                                            }
                                        </div>

                                        <div className="products-content">
                                            <h3>
                                                <Link to={`/shop/${product.id}/${product.name.replace(/\s/g, '').toLowerCase()}`}>{product.name}</Link>
                                            </h3>
                                            <span>&#163;{product.price}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}




                    </div>

                    {curProducts?.length > dataPerPage ?
                        <div className="book-store-btn">
                            <Button onClick={() => setDataPerPage(dataPerPage + 4)} className="default-btn">Load More <i><BiLoaderCircle /></i></Button>
                        </div> : null}
                </div>

                <div className="products-main-shape">
                    <img data-cfsrc="assets/images/products/shape-1.png" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/shape-1.png" alt="image" /></noscript>
                </div>
            </section>
        </>
    )
}

export default Shop