import React, { useState, useRef, useEffect, useContext } from 'react';

import axios from 'axios';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import logo from '../assets/logo.png';
import Radio from '@mui/material/Radio';
import { Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
// import RadioGroup from '@mui/material/RadioGroup';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Paypal from '../common/payment/Paypal';
import { LogoContext } from '../../Forms';


const StopSmoking = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const logo = useContext(LogoContext);

    // const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [name1, setName1] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phone1, setPhone1] = useState('');
    const [client, setClient] = useState('');
    const [date2, setDate2] = useState('');
    const [dob, setDob] = useState('');
    const [marital, setMarital] = useState('');
    const [Occupation, setOccupation] = useState('');
    const [address, setAddress] = useState('');
    const [cigarettes, setCigarettes] = useState('');
    const [years, setYears] = useState('');
    const [smokereason, setSmokeReason] = useState('');
    const [smoked, setSmoke] = useState([]);
    const [smoking, setSmoking] = useState('');
    const [medication, setMedication] = useState('');
    const [about, setAbout] = useState('');
    const [date1, setDate1] = useState('');
    const [stopreason, setStopReason] = useState([]);
    const [doctorRecommend, setDoctorRecommend] = useState('');
    const [doctor, setDoctor] = useState('');
    const [client_signature, setclient_signature] = useState('');
    const [pending, setPending] = useState(true);
    const [isPay, setIsPay] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('');
    const [client_Id, setClient_Id] = useState('');



    const formSchema = yup.object().shape({
        name: yup.string().required(),
        name_one: yup.string().required(),
        email: yup.string().email().required(),
        date_one: yup.string().required(),
        dob: yup.string().required(),
        client: yup.string().required(),
        marital: yup.string().required(),
        address: yup.string().min(10).required(),
        phone: yup.string().min(10).required(),
        phone_number: yup.string().min(10).required(),
        reason: yup.string().required(),
        occupation: yup.string().required(),
        cigarettes: yup.string().required(),
        when_smoke: yup.array().min(1).of(yup.string().required()).required(),
        handle_reason: yup.array().min(1).of(yup.string().required()).required(),
        year: yup.string().required(),
        smoke: yup.string().required(),
        medication: yup.string().required(),
        doctor: yup.string().required(),
        doctor_recommends: yup.string().required(),
        about: yup.string().required(),
        date_two: yup.string().required(),
        signature: yup
            .string()
            .required()
            .matches(
                /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
                "Signature must be png"
            ),
    });

    const sigRef = useRef();
    const handleSignatureEnd = () => {
        if (sigRef.current) {
            const dataURL = sigRef.current.toDataURL();
            setclient_signature(dataURL);
            return dataURL;
        }

    };


    const clear = () => {
        sigRef.current.clear();
        reset({
            signature: ''
        })
    }

    const getData = () => {
        axios.get(`${api_url}/getamountsetting`).then((response) => {

            setAmount(response?.data?.data[0]?.minimun_amt)
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

        axios.get(`${api_url}/getAdminSetting1`).then((response) => {

            setClient_Id(response?.data?.data[0]?.paypal_client)
        }).catch((err) => {

            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);


    let smoke = [
        'On waking', 'At breakfast', 'After meals', 'Driving', 'With tea/coffee',
        'On the phone', 'At work', 'In bed', 'Other (Write Below)'
    ]

    let importreason = [
        'Controlled by Cigarettes', 'Coughs and Colds', 'Money / Expense of Smoking', 'Breathing', 'Children / Grandchildren', 'Death and Dying'
        , 'Smell of Smoking', 'Pressure from others', 'Current Health Problems', 'Inconvenience', 'Currently Healthy but Concerned about Future'
        , 'Anti-Social'
    ]

    const HandleSmoke = (e) => {

        let checkedData = [...smoked];

        if (e.checked) {
            checkedData = [...smoked, { value: e.value }]

        } else {
            let Index = smoked.findIndex(data => data.value == e.value)
            checkedData.splice(Index, 1)
        }

        let myData = checkedData
        setSmoke(myData);

    }


    const HandleReason = (e) => {

        let checkedData = [...stopreason];

        if (e.checked) {
            checkedData = [...stopreason, { value: e.value }]

        } else {
            let Index = stopreason.findIndex(data => data.value == e.value)
            checkedData.splice(Index, 1)
        }

        let myData = checkedData
        setStopReason(myData);

    }

    const notify = () => toast.success("Form Submit SuccessFully", { position: "top-center" });


    const verified = () => {
        setIsPay(true);
    }


    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors } = {},
    } = useForm({
        // mode: "onBlur",
        resolver: yupResolver(formSchema),
    });

    const onSubmit = () => {
        setPending(true);
        axios.post(`${api_url}/smoke`, {
            name: name,
            email: email,
            phone: phone,
            client_id: client,
            name1: name1,
            date1: date2,
            marital_status: marital,
            dob: dob,
            phone1: phone1,
            occuption: Occupation,
            cigarettes_count: cigarettes,
            smoke_reason: smokereason,
            address: address,
            years: years,
            smoke: smoked?.map(data => data.value).toString(),
            stop_reason: stopreason?.map(data => data.value).toString(),
            stop_smoke_reason: smoking,
            medication: medication,
            doctor: doctor,
            doctor_recommend: doctorRecommend,
            about: about,
            date2: date1,
            client_signature: client_signature,
            tnx_id: '',
            amount: '',

        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setIsPay(false);
                setPending(false);
                setAmount('')
                window.location.reload(false);
                // window.location.reload(true);
            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    return (
        <>
            {pending ?
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div> : null}
            <article>
                <div className='title text-center'>
                    <img style={{ borderRadius: "50%" }} src={`${api_url}/assets/logo/${logo}`} width={100} height={99} alt="image" />
                    <h1 className='text-center pb-5'>THE PATHWAY HYPNOTHERAPY ROOMS</h1>
                </div>
                <ToastContainer />

                <div className='content-d pb-5'>
                    <h1 className='heading'>Stop Smoking with Glen @ The Pathway Hypnotherapy Rooms</h1>
                </div>

                <form id="contactForm" className='row m-0' onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group col-12">
                        <label>Name</label>
                        <input type="text" id="name1" className="form-control" placeholder="Name"

                            {...register('name_one', {
                                required: true,
                                onChange: (e) => setName1(e.target.value)

                            })}
                        />
                        {errors.name_one && errors.name_one.type && <div className="help-block with-errors">Please Enter Your Name</div>}

                    </div>
                    <div className="form-group col-12">
                        <label>Email</label>
                        <input type="email" id="email" className="form-control" placeholder="Email"
                            value={email}

                            {...register('email', {
                                required: true,
                                onChange: (e) => setEmail(e.target.value)
                            })}
                        />
                        {errors.email && errors.email.type && <div className="help-block with-errors">Please Enter Email</div>}
                    </div>


                    <div className="form-group col-12">
                        <label>Phone</label>
                        <input type="text" id="phone_number" className="form-control" placeholder="Phone"

                            {...register('phone_number', {
                                required: true,
                                onChange: (e) => setPhone1(e.target.value)
                            })}
                        />
                        {errors.phone_number && errors.phone_number.type && <div className="help-block with-errors">Please Enter Phone</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>Client Ref. No.</label>
                        <input type="text" id="client" className="form-control" placeholder="Client Ref "

                            {...register('client', {
                                required: true,
                                onChange: (e) => setClient(e.target.value)
                            })}
                        />
                        {errors.client && errors.client.type && <div className="help-block with-errors">Please Enter Client Ref Number</div>}
                    </div>


                    <div className="form-group col-6">
                        <label>Name</label>
                        <input type="text" id="name" className="form-control" placeholder="name"

                            {...register('name', {
                                required: true,
                                onChange: (e) => setName(e.target.value)

                            })}
                        />
                        {errors.name && errors.name.type && <div className="help-block with-errors">Please Enter Name</div>}

                    </div>
                    <div className="form-group col-6">
                        <label>Date</label>
                        <input type="date" id="date2" className="form-control" placeholder=""

                            {...register('date_two', {
                                required: true,
                                onChange: (e) => setDate2(e.target.value)

                            })}
                        />
                        {errors.date_two && errors.date_two.type && <div className="help-block with-errors">Please Enter Date</div>}

                    </div>
                    <div className="form-group col-6">
                        <label>Dob</label>
                        <input type="date" id="dob" className="form-control" placeholder=""

                            {...register('dob', {
                                required: true,
                                onChange: (e) => setDob(e.target.value)

                            })}
                        />
                        {errors.dob && errors.dob.type && <div className="help-block with-errors">Please Select DOB Date</div>}

                    </div>
                    <div className="form-group col-6">
                        <label>Phone No.</label>
                        <input type="text" id="phone" className="form-control" placeholder="phone"

                            {...register('phone', {
                                required: true,
                                onChange: (e) => setPhone(e.target.value)

                            })}
                        />
                        {errors.phone && errors.phone.type && <div className="help-block with-errors">Please Enter Phone Number</div>}

                    </div>
                    <div className="form-group col-6">
                        <label>Marital Status</label>
                        <Form.Select aria-label="Default select example" className='form-control'
                            {...register('marital', {
                                required: true,
                                onChange: (e) => setMarital(e.target.value)
                            })}>
                            <option value=''>Marital Status</option>
                            <option value="1">Single</option>
                            <option value="2">Married</option>
                            <option value="3">Widowed</option>
                            <option value="4">Divorced</option>

                        </Form.Select>
                        {errors.marital && errors.marital.type && <div className="help-block with-errors">Please Marital Status</div>}
                    </div>
                    <div className="form-group col-6">
                        <label>Occupation</label>
                        <input type="text" id="occupation" className="form-control" placeholder="Occupation"

                            {...register('occupation', {
                                required: true,
                                onChange: (e) => setOccupation(e.target.value)

                            })}
                        />
                        {errors.occupation && errors.occupation.type && <div className="help-block with-errors">Please Enter Occupation</div>}

                    </div>

                    <div className="form-group col-12">
                        <label>Address</label>
                        <textarea className="form-control" id="address" cols="30" rows="2" placeholder="Address"


                            {...register('address', {
                                required: true,
                                onChange: (e) => setAddress(e.target.value)
                            })}
                        ></textarea>
                        {errors.address && errors.address.type && <div className="help-block with-errors">Please Enter Address</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>How many cigarettes do you smoke in a day?</label>
                        <textarea className="form-control" id="cigarettes" cols="30" rows="2" placeholder="How many cigarettes do you smoke in a day?"


                            {...register('cigarettes', {
                                required: true,
                                onChange: (e) => setCigarettes(e.target.value)
                            })}
                        ></textarea>
                        {errors.cigarettes && errors.cigarettes.type && <div className="help-block with-errors">Please Enter Cigarettes Quantity</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>For how many years have you been smoking?</label>
                        <textarea className="form-control" id="year" cols="30" rows="2" placeholder="For how many years have you been smoking?"


                            {...register('year', {
                                required: true,
                                onChange: (e) => setYears(e.target.value)
                            })}
                        ></textarea>
                        {errors.year && errors.year.type && <div className="help-block with-errors">Please Enter years</div>}
                    </div>
                    <div className='heading pt-4'>
                        <h5>When do you smoke? </h5>
                    </div>
                    <div className='d-flex pb-4' style={{ flexWrap: 'wrap' }}>
                        {smoke?.map((data) => {
                            return (

                                <div key={data}  >
                                    <FormControlLabel
                                        value={data}
                                        // id={data}
                                        control={<Checkbox className='text-dark' />}
                                        label={data}
                                        labelPlacement='end'

                                        // onChange={(e) => HandleSmoke(e.target)}
                                        {...register('when_smoke', {
                                            required: true,
                                            onChange: (e) => HandleSmoke(e.target)
                                        })}
                                    />

                                </div>
                            )
                        })}


                    </div>
                    {errors.when_smoke && <div className="help-block with-errors">This field is required</div>}
                    <div className="form-group col-12">
                        <label>When do you smoke? (Write other reason)</label>
                        <input type="text" id="reason" className="form-control" placeholder="When do you smoke?"
                            {...register('reason', {
                                required: true,
                                onChange: (e) => setSmokeReason(e.target.value)

                            })} />
                        {errors.reason && errors.reason.type && <div className="help-block with-errors">Please Enter Reason</div>}
                    </div>

                    <div className='heading pt-4'>
                        <h5>What’s the most important reason for you stopping? (Tick Min.3)  </h5>
                    </div>
                    <div className='d-flex pb-4' style={{ flexWrap: 'wrap' }}>
                        {importreason?.map((data) => {
                            return (

                                <div key={data} >
                                    <FormControlLabel
                                        value={data}
                                        // id={data}
                                        control={<Checkbox className='text-dark' />}
                                        label={data}
                                        labelPlacement='end'

                                        // onChange={(e) => HandleReason(e.target)}
                                        {...register('handle_reason', {
                                            required: true,
                                            onChange: (e) => HandleReason(e.target)
                                        })}

                                    />

                                </div>
                            )
                        })}


                    </div>
                    {errors.handle_reason && <div className="help-block with-errors">This field is required</div>}
                    <div className="form-group col-12">
                        <label>What methods have you tried (if any) to stop smoking?</label>
                        <input type="text" id="name" className="form-control" placeholder="What methods have you tried to stop smoking?"
                            {...register('smoke', {
                                required: true,
                                onChange: (e) => setSmoking(e.target.value)

                            })} />
                        {errors.smoke && errors.smoke.type && <div className="help-block with-errors">Please Enter Method</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>Are you currently on any medication? Please list</label>
                        <input type="text" id="medication" className="form-control" placeholder="medication list"
                            {...register('medication', {
                                required: true,
                                onChange: (e) => setMedication(e.target.value)

                            })} />
                        {errors.medication && errors.medication.type && <div className="help-block with-errors">Please Enter Medication</div>}
                    </div>
                    <div className='heading pt-4'>
                        <h5>Are you currently under the care of a doctor? </h5>
                    </div>
                    <div className='d-flex' style={{ flexWrap: 'wrap' }}>


                        <Radio
                            checked={doctor === '1'}
                            className='text-dark'

                            value="1"
                            name="doctor"
                            {...register('doctor', {
                                required: 'This field is required',
                                onChange: (e) => setDoctor(e.target.value)

                            })}

                        /> Yes
                        <Radio
                            checked={doctor === '2'}
                            className='text-dark'

                            value="2"
                            name="doctor"
                            {...register('doctor', {
                                required: 'This field is required',
                                onChange: (e) => setDoctor(e.target.value)

                            })}

                        /> No


                    </div>
                    {errors.doctor && <div className="help-block with-errors">This field is required</div>}
                    <div className='heading pt-4'>
                        <h5>Did your doctor recommend that you stop smoking?</h5>
                    </div>
                    <div className='d-flex' style={{ flexWrap: 'wrap' }}>


                        <Radio
                            checked={doctorRecommend === '1'}
                            className='text-dark'

                            value="1"
                            name="doctor_recommends"
                            {...register('doctor_recommends', {
                                required: 'This field is required',
                                onChange: (e) => setDoctorRecommend(e.target.value)

                            })}

                        /> Yes
                        <Radio
                            checked={doctorRecommend === '2'}
                            className='text-dark'

                            value="2"
                            name="doctor_recommends"
                            {...register('doctor_recommends', {
                                required: 'This field is required',
                                onChange: (e) => setDoctorRecommend(e.target.value)

                            })}

                        /> No

                    </div>
                    {errors.doctor_recommends && <div className="help-block with-errors">This field is required</div>}

                    <div className="form-group col-12">
                        <label>Who refereed you, or how did you hear about us?</label>
                        <input type="text" id="name" className="form-control" placeholder="Who refereed you"
                            {...register('about', {
                                required: true,
                                onChange: (e) => setAbout(e.target.value)

                            })} />
                        {errors.about && errors.about.type && <div className="help-block with-errors">Please Enter About Data</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>Date</label>
                        <input type="date" id="date" className="form-control" placeholder=""
                            {...register('date_one', {
                                required: true,
                                onChange: (e) => setDate1(e.target.value)

                            })} />
                        {errors.date_one && errors.date_one.type && <div className="help-block with-errors">Please Enter Date</div>}
                    </div>


                    <div className="form-group ">
                        <label>Signature</label>
                        <div>


                            <Controller
                                name="signature"
                                control={control}
                                render={({ field }) => (
                                    <SignatureCanvas penColor='black'
                                        backgroundColor='white'
                                        canvasProps={{ className: 'signature' }}
                                        ref={sigRef}
                                        onEnd={() => field.onChange(handleSignatureEnd())}
                                    />
                                )}
                            />
                            <div className="help-block with-errors">{errors?.signature?.message}</div>
                            <div className='col-md-1 form_btn_d m-3'>
                                <Button type="button" className='default-btn' style={{ backgroundColor: 'black' }}
                                    onClick={clear}
                                >Clear</Button>
                            </div>
                        </div>
                    </div>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                    <div className='col-md-1 form_btn_d m-3'>

                        {isPay === false ?
                            <Button type="submit" className='default-btn' style={{ backgroundColor: 'black' }}  >Submit
                                <i><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z"></path></svg></i>
                            </Button>
                            : null}

                        {/* {isPay === true ?
                            <Paypal client_Id={client_Id} amount={amount} onBooking={onSubmit} setTransactionId={setTransactionId} />
                            : null} */}

                    </div>
                </form >


                <div className="copyright-area" style={{ backgroundColor: 'rgb(32 37 41)' }}>
                    <div className="container">
                        <div className="copyright-area-content">
                            <p style={{ color: '#fff' }}>
                                Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved

                            </p>
                        </div>
                    </div>
                </div>
            </article >
        </>

    )
}

export default StopSmoking