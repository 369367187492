import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { createContext } from 'react';
import axios from 'axios';

export const LogoContext = createContext(null);

const Forms = () => {


    const [logo, setLogo] = useState('');

    const api_url = process.env.REACT_APP_API_URL;

    const getData = () => {
        axios.get(`${api_url}/adminProfile`).then((response) => {
            setLogo(response?.data?.data[0]?.logo)
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <>
            <LogoContext.Provider value={logo}>
                <main className='forms_'>
                    <Outlet />
                </main>
            </LogoContext.Provider>
        </>
    )
}

export default Forms