import React, { useState, useEffect, memo } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import { SlCalender } from 'react-icons/sl';
import RelatedBlogs from '../common/RelatedBlogs';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { seoData } from '../../../data';

const SingleBlog = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();

    const [blogs, setBlogs] = useState([]);
    const [seoContent, setSeoContent] = useState([]);
    const [banner, setBanner] = useState('');
    const [allBlogs, setAllBlogs] = useState([]);
    const [pending, setPending] = useState(false);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');

    const getData = () => {


        axios.get(`${api_url}/getBanner/9`).then((response) => {

            setBanner(response?.data?.data[0]?.image)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/singleBlog/${id}`).then((response) => {
            setBlogs(response?.data?.data);
            setSeoContent(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/getAllBlog`).then((response) => {
            setAllBlogs(response?.data?.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [id])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    // singleBlog

    let defaultSeoData = seoData.blogDetails.filter(item => item.id == id);

    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : defaultSeoData[0]?.title}</title>
                <meta name="description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta property="og:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            {/* <div className="page-banner-area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-image">
                                <img data-cfsrc="assets/images/page-banner/page-banner-3.jpg" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/page-banner-3.jpg" alt="image" /></noscript>

                                    <div className="image-shape">
                                        <img data-cfsrc="assets/images/page-banner/shape.png" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/shape.png" alt="image" /></noscript>
                                    </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-content">
                                <h2>Blog Details</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>Blog Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-banner-shape">
                    <img data-cfsrc="assets/images/page-banner/banner-shape-1.png" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/banner-shape-1.png" alt="image" /></noscript>
                </div>
            </div> */}

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="page-banner-with-full-image item-bg2" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                <div className="container">
                    <div className="page-banner-content-two">
                        <h2>Blog </h2>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li>{blogs[0]?.category_id}</li>
                            <li>{blogs[0]?.title}</li>
                        </ul>
                    </div>
                </div>
            </div>

            {blogs?.map((blog) => {

                return (
                    <div className="blog-details-area" style={{ padding: "45px 0px" }}>
                        <div className="container">
                            <div className="blog-details-desc">
                                <div className="article-content blog_d">
                                    <div className="title-box">
                                        <h2>{blog.title}</h2>
                                        <span className='d-flex align-items-center gap-2 justify-content-center pb-3' ><SlCalender />{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })} {new Date(blog.created_at).toLocaleDateString('en-En', { month: 'long' })} {new Date(blog.created_at).toLocaleDateString('en-En', { year: 'numeric' })}</span>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <div className="article-image pb-4">
                                                <img className='w-100' src={`${api_url}/assets/blogs/${blog.image}`} alt="image" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <HTMLRenderer html={blog.content} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            <RelatedBlogs blogs={allBlogs?.filter(data => data.id != id)} title={"Related Blogs"} />

        </>
    )
}

export default memo(SingleBlog)