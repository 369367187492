import { Rating } from '@mui/material'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { AiOutlineShoppingCart, AiTwotoneCustomerService } from 'react-icons/ai';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import RelatedProduct from '../common/RelatedProduct';
import Spinner from 'react-bootstrap/Spinner';
import HTMLRenderer from 'react-html-renderer';
import { Helmet } from "react-helmet";
import { seoData } from '../../../data';

const Product = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();

    const [addToCart] = useOutletContext();
    const [product, setProduct] = useState([]);
    const [products, setProducts] = useState([]);
    // const [qty, setQty] = useState(1)
    const [pending, setPending] = useState(false)

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');
    const getData = () => {

        axios.get(`${api_url}/getAllProduct`).then((response) => {

            setProducts(response?.data?.data);
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/singleProduct/${id}`).then((response) => {

            setProduct(response?.data?.data);
            setSeoContent(response?.data?.data);

            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [id])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    const addTo = (id, name, qty, price, image) => {
        addToCart(id, name, qty, price, image)

    }

    let defaultSeoData = seoData.blogDetails.filter(item => item.id == id);

    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.controlSystem.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.controlSystem.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.controlSystem.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.controlSystem.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.controlSystem.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.controlSystem.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>
            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <section className="products-details-area " style={{ padding: '45px 0px' }}>
                <div className="container">

                    <div className="page-banner-content">
                        {/* <h2>Therapies</h2> */}
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            {/* <li>
                                <Link to="/shop">Product</Link>
                            </li> */}
                            <li>
                                {product[0]?.name}
                            </li>

                        </ul>
                    </div>

                    <div className="row m-0 pt-4">
                        {product?.map((product) => {
                            return (
                                <>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="products-details-slides">
                                            <div className="products-feedback">
                                                <div>
                                                    <div className="item">
                                                        <div className="products-image">
                                                            <img src={`${api_url}/assets/product/${product.image}`} alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <div className="products-details-desc">
                                            <h1>{product.name}</h1>

                                            <p>
                                                <HTMLRenderer html={product.content.substring(0, 626)} />
                                            </p>

                                            <div className="price">
                                                <span className="new-price">&#163;{product.price}</span>
                                            </div>



                                            <div className="products-add-to-cart">
                                                {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == product.id) === false ?
                                                    <button type="submit" className="default-btn _submit_btn_p"
                                                        // disabled={qty === 0 ? true : false}
                                                        onClick={() => addTo(product.id, product.name, 1, product.price, product.image)}
                                                    >Add to Cart<i><AiOutlineShoppingCart /></i></button>
                                                    : <button type="submit" className="default-btn _submit_btn_p"
                                                        disabled
                                                    >Added to Cart<i><AiOutlineShoppingCart /></i></button>}

                                                {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == product.id) === false ? null :
                                                    <Link to="/checkout" className="optional-btn added_to_cart">Process To Checkout<i><AiOutlineShoppingCart /></i></Link>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>

                    <div style={{ paddingTop: '45px' }}>
                        <RelatedProduct data={products.filter(data => data.id != id)} title="More Hypnosis Recordings" />
                    </div>


                </div>


            </section>
        </>
    )
}

export default Product